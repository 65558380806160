export const setDisableFn = (orgTreeListCopy, targetId) => {
  const findNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.org_id === id || node.menu_id === id || node.func_id === id) {
        return node
      }
      if (node.children) {
        const foundInChildren = findNodeById(node.children, id)
        if (foundInChildren) {
          return foundInChildren
        }
      }
    }
    return null
  }

  const disableNodeAndChildren = (node) => {
    node.disabled = true
    if (node.children) {
      node.children.forEach((child) => {
        disableNodeAndChildren(child)
      })
    }
  }

  const disableNodeAndParents = (node, rootNode) => {
    console.log(node, rootNode)
    if (node.parent) {
      const parentNode = findNodeById(rootNode, node.parent)
      if (parentNode) {
        parentNode.disabled = true
        // disableNodeAndParents(parentNode, rootNode)
      }
    } else {
      const parentNode = findNodeById(rootNode, node.parent)
      parentNode.disabled = true

      console.log(parentNode)
    }
  }

  const targetNode = findNodeById(orgTreeListCopy, targetId)
  if (targetNode) {
    disableNodeAndChildren(targetNode)
    disableNodeAndParents(targetNode, orgTreeListCopy)
  }
}
