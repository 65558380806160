import Cookie from 'js-cookie'
import psl from 'psl'

const Token = 'Token'
const SuperGroup = 'super_group'
const Company = 'company'
const OaOrg = 'oaorg'
const Deploy = 'deploy'
const TitleName = 'title_name'
const LogoUrl = 'logo_url'
const Creator = 'co_creator'
const Admin = 'co_admin'
const Refresh = 'refresh'
const RedirectUrlKey = 'sys_redirectUrl'
const UserStatus = 'user_status'
const UrlSearch = 'url_search'

const getTokenKey = () => {
  const prefix = localStorage.getItem('prefix')
  return prefix ? prefix + '_' + Token : Token
}
let domain = psl.get(window.location.hostname)
domain = domain && `.${domain}`
/**
 * 存储token
 * @param {*} token
 */
export function setToken(token) {
  let options = {
    expires: new Date(new Date().getTime() + 23 * 60 * 60 * 1000),
  }
  if (domain) {
    options.domain = domain
  }
  Cookie.set(getTokenKey(), token, options)
}
export function setSuperGroup(super_group) {
  Cookie.set(SuperGroup, super_group, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setCompany(company) {
  Cookie.set(Company, company, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setOaOrg(oaorg) {
  Cookie.set(OaOrg, oaorg, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setDeploy(deploy) {
  Cookie.set(Deploy, deploy, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setTitleName(title_name) {
  Cookie.set(TitleName, title_name, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setLogoUrl(logo_url) {
  Cookie.set(LogoUrl, logo_url, {
    expires: new Date().getTime() + 23 * 60 * 60 * 1000,
  })
}
export function setCreator(co_creator) {
  Cookie.set(Creator, co_creator)
}
export function setAdmin(co_admin) {
  Cookie.set(Admin, co_admin)
}

export function setRedirectUrl(logo_url) {
  Cookie.set(RedirectUrlKey, logo_url)
}

export function setRefresh(refresh) {
  Cookie.set(Refresh, refresh)
}

export function setUserStatus(userStatus) {
  Cookie.set(UserStatus, userStatus)
}

export function setUrlSearch(urlSearch) {
  Cookie.set(UrlSearch, urlSearch)
}

export function setCallbackUrl(callbackUrl) {
  let options = {
    expires: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
  }
  if (domain) {
    options.domain = domain
  }
  Cookie.set('callbackUrl', callbackUrl, options)
}

/**
 * 获取token
 * @returns
 */
export function getRedirectUrl() {
  return Cookie.get(RedirectUrlKey)
}
export function getToken() {
  return Cookie.get(getTokenKey())
}
export function getSuperGroup() {
  return JSON.parse(Cookie.get(SuperGroup) || 'false')
}
export function getCompany() {
  return Cookie.get(Company)
}
export function getOaOrg() {
  return Cookie.get(OaOrg)
}
export function getDeploy() {
  return Cookie.get(Deploy)
}
export function getTitleName() {
  return Cookie.get(TitleName)
}
export function getLogoUrl() {
  return Cookie.get(LogoUrl)
}
export function getCreator() {
  return JSON.parse(Cookie.get(Creator) || 'false')
}
export function getAdmin() {
  return JSON.parse(Cookie.get(Admin) || 'false')
}
export function getRefresh() {
  return JSON.parse(Cookie.get(Refresh) || 'false')
}
export function getUserStatus() {
  return Cookie.get(UserStatus)
}
export function getUrlSearch() {
  return Cookie.get(UrlSearch)
}
export function getCallbackUrl() {
  return Cookie.get('callbackUrl')
}
/**
 * 移除token
 * @returns
 */
export function removeToken() {
  let options = {}
  if (domain) {
    options.domain = domain
  }
  return Cookie.remove(getTokenKey(), options)
}
export function removeSuperGroup() {
  return Cookie.remove(SuperGroup)
}
export function removeCompany() {
  return Cookie.remove(Company)
}
export function removeOaOrg() {
  return Cookie.remove(OaOrg)
}
export function removeDeploy() {
  return Cookie.remove(Deploy)
}
export function removeTitleName() {
  return Cookie.remove(TitleName)
}
export function removeLogoUrl() {
  return Cookie.remove(LogoUrl)
}
export function removerCreator() {
  return Cookie.remove(Creator)
}
export function removeAdmin() {
  return Cookie.remove(Admin)
}
export function removeRefresh() {
  return Cookie.remove(Refresh)
}
export function removeUserStatus() {
  return Cookie.remove(UserStatus)
}
export function removeUrlSearch() {
  return Cookie.remove(UrlSearch)
}
/**
 * 判断是否有token
 * @returns
 */
export function hasToken() {
  return !!getToken()
}
