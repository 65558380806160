import styles from './index.module.scss'
import logo from '../../../../assets/logo.png'
import MyButton from '../../../../components/MyButton'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Space, message, Typography, Progress } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import api from '../../../../api'
import { useGetState, useSize } from 'ahooks'
import moment from 'moment'

const { Paragraph, Text } = Typography

export default function SubCard(props) {
  const {
    info = {},
    style,
    noBtn = false,
    deleteIcon = false,
    deleteCallBack,
    btnText = '升级会员',
    btnCallBack,
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [delPermis, setDelPermis] = useState(false)
  const [max, setMax] = useState(3)
  const [planNumInfo, setPlanNumInfo, getPlanNumInfo] = useGetState([])

  const ref = useRef(null)
  const size = useSize(ref)

  useEffect(() => {
    // const num = Math.floor(size?.height / 32);
    let num = 0
    let maxH = size?.height
    getPlanNumInfo()?.forEach((_) => {
      maxH = maxH - _.height
      if (maxH > 5) {
        num += 1
      } else {
        return
      }
    })
    setMax(num)
  }, [size, planNumInfo])

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const delPermiss = () => {
    api.deleteLicense(info.license_id).then((res) => {
      if (res.data.code === 0) {
        setDelPermis(false)
        message.success('删除许可成功')
        deleteCallBack && deleteCallBack()
      } else {
        message.error(res.data.msg)
        setDelPermis(false)
      }
    })
  }

  const getSubPlanNum = () => {
    api.getSubsPlanNum(info.prd_code).then((res) => {
      if (res.data.code === 0) {
        const numInfo = res.data.data;
        const item = JSON.parse(JSON.stringify(info.license_items))?.map(
          (_) => {
            _.height = 32;
            const list = numInfo[_.license_item_code];
            if (list && Number(_.item_auth_value || 0)) {
              _.showProgress = true;
              _.usedNum = list?.at(0);
              _.height = _.item_auth_value === "-1" ? 30 : 40;
            }
            return _;
          }
        );
        setPlanNumInfo(item);
      } else {
        setPlanNumInfo([]);
        message.error(res.data.msg);
      }
    });
  };

  useEffect(() => {
    getSubPlanNum()
    console.log('--------useEffect-------getSubPlanNum---------')
  }, [])

  const initSpan = (item) => {
    let style = {}

    if (item.license_item_code === 'LICENSE_DATE') {
      //截止日期 即将到期
      if (
        moment(new Date())
          .add(item.item_alert_value || 0, 'days')
          .isAfter(item.item_auth_value)
      ) {
        style.color = '#ff4d4f'
      }
    } else if (item.showProgress) {
      //余量 到达 阈值
      if (item.item_auth_value - item.usedNum <= item.item_alert_value) {
        style.color = '#ff4d4f'
      }
      //不限数量
      if (item.item_auth_value === '-1') {
        delete style.color
      }
      //不限数量
      if (item.item_auth_value === '-1') {
        delete style.color
      }
      //不限数量
      if (item.item_auth_value === "-1") {
        delete style.color;
      }
    }

    if (item.showProgress) {
      if (item.item_auth_value === "-1") {
        return <span>不限</span>;
      }
      return (
        <>
          <span style={style}>{item.usedNum}</span>
          <span style={{ margin: "0 2px" }}>/</span>
          <span>
            {item.item_auth_value === "-1" ? "不限" : item.item_auth_value}
          </span>
        </>
      )
    }
    return (
      <span style={style}>
        {item?.license_item_type_code === 'AUTH_MENU_FUNC'
          ? '支持'
          : item.item_auth_value}
      </span>
    )
  }
  //筛选出不是菜单的
  // const liceseItems = (info?.license_items || [])
  // ?.filter((_) => {
  //   return _.license_item_type_code !== "AUTH_MENU_FUNC";
  // });

  return (
    <div className={styles.root} style={{ ...style }}>
      <div className="subCard">
        <div className="headerLogo">
          <img src={info?.prod_url || logo} alt="" />
          <Paragraph
            style={{ width: '80%', marginBottom: '0px' }}
            ellipsis={{
              rows: 1,
              tooltip: info?.subs_plan_name,
            }}
          >
            {info?.subs_plan_name}
          </Paragraph>
        </div>
        <div className="licenseItemsBox" ref={ref}>
          {(planNumInfo || [])?.slice(0, max)?.map((item, index) => (
            <div className="licenseItem" key={index}>
              <div className="itemBox">
                <div className="itemTitle">
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      tooltip: item.license_item_name,
                    }}
                  >
                    {item.license_item_name}
                  </Paragraph>
                </div>
                <div className="itemLabel">
                  <Paragraph
                    ellipsis={{
                      rows: 1,
                      tooltip: item.showProgress ? (
                        <>
                          <span>{item.usedNum}</span>
                          <span style={{ margin: '0 2px' }}>/</span>
                          <span>{item.item_auth_value}</span>
                        </>
                      ) : item?.license_item_type_code === 'AUTH_MENU_FUNC' ? (
                        '支持'
                      ) : (
                        item.item_auth_value
                      ),
                    }}
                  >
                    {initSpan(item)}
                  </Paragraph>
                </div>
              </div>
              {item.showProgress && item.item_auth_value !== "-1" && (
                <Progress
                  percent={(item.usedNum / item.item_auth_value) * 100}
                  showInfo={false}
                  size="small"
                  strokeColor="#006ab2"
                />
              )}
            </div>
          ))}
          {(planNumInfo || [])?.length > max && (
            <div className="moreBox">
              <span
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                更多
              </span>
            </div>
          )}
        </div>
        {noBtn ? null : (
          <div className="btnBox">
            <MyButton
              type="primary"
              onClick={() => {
                if (btnCallBack) {
                  btnCallBack(info)
                  return
                }
                window.open(info?.cs_url, '_target')
              }}
            >
              {btnText}
            </MyButton>
          </div>
        )}
        {deleteIcon ? (
          <div className="deleteBox">
            <svg
              onClick={() => {
                setDelPermis(true)
              }}
              t="1705891931298"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="5147"
              width="14"
              height="14"
            >
              <path
                d="M607.897867 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L575.903242 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 351.94087C639.892491 753.593818 625.61532 768.043004 607.897867 768.043004z"
                fill="#575B66"
                p-id="5148"
              ></path>
              <path
                d="M415.930119 768.043004c-17.717453 0-31.994625-14.277171-31.994625-31.994625L383.935495 383.935495c0-17.717453 14.277171-31.994625 31.994625-31.994625 17.717453 0 31.994625 14.277171 31.994625 31.994625l0 351.94087C447.924744 753.593818 433.647573 768.043004 415.930119 768.043004z"
                fill="#575B66"
                p-id="5149"
              ></path>
              <path
                d="M928.016126 223.962372l-159.973123 0L768.043004 159.973123c0-52.980346-42.659499-95.983874-95.295817-95.983874L351.94087 63.989249c-52.980346 0-95.983874 43.003528-95.983874 95.983874l0 63.989249-159.973123 0c-17.717453 0-31.994625 14.277171-31.994625 31.994625s14.277171 31.994625 31.994625 31.994625l832.032253 0c17.717453 0 31.994625-14.277171 31.994625-31.994625S945.73358 223.962372 928.016126 223.962372zM319.946246 159.973123c0-17.545439 14.449185-31.994625 31.994625-31.994625l320.806316 0c17.545439 0 31.306568 14.105157 31.306568 31.994625l0 63.989249L319.946246 223.962372 319.946246 159.973123 319.946246 159.973123z"
                fill="#575B66"
                p-id="5150"
              ></path>
              <path
                d="M736.048379 960.010751 288.123635 960.010751c-52.980346 0-95.983874-43.003528-95.983874-95.983874L192.139761 383.591466c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 480.435411c0 17.717453 14.449185 31.994625 31.994625 31.994625l448.096758 0c17.717453 0 31.994625-14.277171 31.994625-31.994625L768.215018 384.795565c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 479.231312C832.032253 916.835209 789.028725 960.010751 736.048379 960.010751z"
                fill="#575B66"
                p-id="5151"
              ></path>
            </svg>
          </div>
        ) : null}
      </div>

      <Modal
        title=""
        centered={true}
        footer={null}
        visible={isModalOpen}
        onCancel={handleCancel}
        wrapClassName="subCardModal"
      >
        <div className="subCardModalBox">
          <div className="headerLogo">
            <img src={info?.prod_url || logo} alt="" />
            <Paragraph
              style={{ width: '90%', marginBottom: '0px' }}
              ellipsis={{
                rows: 1,
                tooltip: info?.subs_plan_name,
              }}
            >
              {info?.subs_plan_name}
            </Paragraph>
          </div>
          <div className="licenseItemsBox">
            {(planNumInfo || [])?.map((item, index) => (
              <div className="licenseItem" key={index}>
                <div className="itemBox">
                  <div className="itemTitle">
                    <Paragraph
                      ellipsis={{
                        rows: 1,
                        tooltip: item.license_item_name,
                      }}
                    >
                      {item.license_item_name}
                    </Paragraph>
                  </div>
                  <div className="itemLabel">
                    <Paragraph
                      ellipsis={{
                        rows: 1,
                        tooltip: item.showProgress ? (
                          <>
                            <span>{item.usedNum}</span>
                            <span style={{ margin: '0 2px' }}>/</span>
                            <span>{item.item_auth_value}</span>
                          </>
                        ) : item?.license_item_type_code ===
                          'AUTH_MENU_FUNC' ? (
                          '支持'
                        ) : (
                          item.item_auth_value
                        ),
                      }}
                    >
                      {initSpan(item)}
                    </Paragraph>
                  </div>
                </div>
                {item.showProgress && item.item_auth_value !== "-1" && (
                  <Progress
                    percent={(item.usedNum / item.item_auth_value) * 100}
                    showInfo={false}
                    size="small"
                    strokeColor="#006ab2"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      {/* 删除对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelPermis(false)}
        title="删除许可"
        visible={delPermis}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn" key={Math.random()}>
            <MyButton onClick={() => setDelPermis(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => delPermiss()}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>确定要删除许可"{info.subs_plan_name}"吗？</p>
      </Modal>
    </div>
  )
}
