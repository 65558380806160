import { useEffect, useState } from 'react'
import './index.scss'
import useUrlState from '@ahooksjs/use-url-state'
import { Result, Spin } from 'antd'
import Api from '../../api/index'

export default function WXLogin() {
  const [state, setState] = useUrlState({})
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)

  const getUserInfo = (code) => {
    Api.getTokenByCode(code).then((res) => {
      console.log(res)
      if (res.data.code === 0) {
        if (res.data.data?.unauthorization) {
          //未授权
        } else if (res.data.data?.expired) {
          //过期
          setExpired(true)
        } else {
          window.sessionStorage.setItem('token', res.data.data?.token)
          window.sessionStorage.setItem('unionid', res.data.data?.unionid)
          window.location.replace(`/wxHtml`)
        }
      }
      setTimeout(() => {
        setLoading(false)
      }, 200)
    })
  }

  useEffect(() => {
    document.title = 'iWudao'
    //获取登录标识
    const str = window.location.pathname?.slice(9) || null
    window.sessionStorage.setItem('loginId', str)
    window.sessionStorage.setItem('isRegister', JSON.stringify(!!state.state))
    //获取用户信息
    if (state.code) {
      getUserInfo(state.code)
    } else {
      setExpired(true)
      setLoading(false)
    }
  }, [])

  return (
    <div className="wxLoginContent">
      {loading ? (
        <Spin size="large"></Spin>
      ) : (
        <Result
          title={!expired ? '请授权后再进行操作!' : '请重新扫描二维码!'}
        />
      )}
    </div>
  )
}
