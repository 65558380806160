import React from 'react'
import nofuncImg from '../../assets/组.png'
import styles from './index.module.scss'
export default function Nofunc() {
  document.title = '无权限'
  return (
    <div className={styles['root']}>
      <div className="content">
        <img src={nofuncImg} alt="" />
        <p className="text">抱歉，您没有访问权限，请联系系统管理员授权！</p>
      </div>
    </div>
  )
}
