import styles from './index.module.scss'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Layout, Menu } from 'antd'
import ReactMarkdown from 'react-markdown'
import { useHistory, useParams } from 'react-router-dom'

const { Header, Content, Sider } = Layout

export default function Help() {
  // const { id } = useParams()
  // console.log(lists)
  // const history = useHistory()
  // const to = (item) => {
  //   console.log(item)
  //   history.push(item.url)
  // }

  // const initMenu = (list, isChildren) => {
  //   console.log(list)
  //   list = list.map((item) => {
  //     if (item.children && item.children.length > 0) {
  //       return (
  //         <Menu.SubMenu title={item.name} key={item.url}>
  //           {initMenu(item.children, true)}
  //         </Menu.SubMenu>
  //       )
  //     }

  //     return (
  //       <Menu.Item key={item.url}>
  //         <a onClick={() => to(item)}>{item.name}</a>
  //       </Menu.Item>
  //     )
  //   })
  //   return list
  // }

  // useEffect(() => {
  //   document.title = '帮助中心'
  //   if (id) {
  //     readFile(id)
  //   }
  // }, [id])

  // const [mdText, setMdText] = useState('')
  // const readFile = (file_name) => {
  //   const mdfile = require(`./help/mds/${file_name}`)
  //   console.log(mdfile)
  //   fetch(mdfile)
  //     .then((res) => {
  //       console.log(res.status)
  //       if (res?.status === 200) {
  //         return res.text()
  //       } else {
  //         return Promise.reject()
  //       }
  //     })
  //     .then((text) => {
  //       console.log(text)
  //       setMdText(text)
  //     })
  //     .catch((error) => console.error(error))
  // }

  // return (
  //   <div className={styles['root']}>
  //     <Layout>
  //       <Header>帮助中心</Header>

  //       <Layout style={{ overflow: 'hidden' }}>
  //         <Sider width={300} style={{ overflow: 'auto', overflowX: 'hidden' }}>
  //           <Menu
  //             mode="inline"
  //             style={{ height: '100%', borderRight: 0 }}
  //             // selectedKeys={[pathname]}
  //             defaultOpenKeys={['/help/10000.md', '/help/10000.md']}
  //           >
  //             {/* 遍历路由 */}
  //             {/* {initMenu(lists)} */}
  //           </Menu>
  //         </Sider>

  //         <Content style={{ padding: 24, overflow: 'auto' }}>
  //           <ReactMarkdown>{mdText}</ReactMarkdown>
  //         </Content>
  //       </Layout>
  //     </Layout>
  //   </div>
  // )

  return <div>帮助中心</div>
}
