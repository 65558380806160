import React, { useEffect, useState } from 'react'
import {
  Card,
  Form,
  Button,
  Space,
  Table,
  ConfigProvider,
  Breadcrumb,
  Input,
  Select,
  Radio,
  Drawer,
  message,
  Modal,
  Tabs,
  Tooltip,
  Tree,
} from 'antd'
import styles from './index.module.scss'
import zhCH from 'antd/lib/locale/zh_CN'
import { useDispatch, useSelector } from 'react-redux'
import {
  addPermission,
  batchAddOrg,
  batchAddUser,
  batchDelOrg,
  batchDelPermission,
  batchDelUser,
  delPermission,
  editPermission,
  getPermissionList,
  getPermissionOrgList,
  getPermissionUserList,
  getMenuPower,
  addMenuPower,
  getFucPower,
  addFucPower,
  getPermissionMenuTreeList,
  getPermissionFuncTreeList,
  getPermissionUserLists,
  getPermissionOrgLists,
  getTrigger,
} from '../../../../store/actions/permission'
import { useRef } from 'react'
import { getCompListForNameCode } from '../../../../store/actions/comp'
import { getUserList } from '../../../../store/actions/user'
import { getOrgList } from '../../../../store/actions/org'
import { getMenuTreeList } from '../../../../store/actions/menu'
import { getFucTreeList } from '../../../../store/actions/fuc'
import { getCompany, getSuperGroup } from '../../../../utils/cookie'
import { MyIcon } from '../../../../routers'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
const { TabPane } = Tabs
export default function RoleManage() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [radio, setRadio] = useState(false)
  const [permissInfo, setPermissInfo, getPermissInfo] = useGetState({})
  const [coId, setCoId, getCoId] = useGetState('')
  const [delPermis, setDelPermis] = useState(false)
  const [batchUserDel, setBatchUserDel] = useState(false)
  const [batchDel, setBatchDel] = useState(false)
  const [watch, setWatch] = useState(false)
  const [userMag, setUserMag] = useState(false)
  const [childrenUserMag, setChildrenUserMag] = useState(false)
  const [childrenOrgMag, setChildrenOrgMag] = useState(false)
  const [delPermisOrg, setDelPermisOrg] = useState(false)
  const [batchOrgDel, setBatchOrgDel] = useState(false)
  const [compId, setCompId] = useState(getCompany())
  const [loadings, setLoadings] = useState(false)
  const [roleId, setRoleId] = useState('')
  const addRef = useRef()
  const tableRef = useRef(null)
  const size = useSize(tableRef)
  const rulesObj = useRef({
    co_id: [
      {
        required: true,
        message: '请选择企业/产品',
      },
    ],
    role_code: [
      {
        required: true,
        message: ' 角色编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          console.log(addRef.current.getFieldsValue())
          const id = addRef.current.getFieldValue(['co_id'])
          console.log(id, getCoId())
          if ((id && value) || !(userInfo?.co_type_code === 'ADMIN_GROUP')) {
            return dispatch(
              getTrigger(
                {
                  pk: getPermissInfo().role_id
                    ? getPermissInfo().role_id
                    : null,
                  co_id: id
                    ? id
                    : getCoId()
                    ? getCoId()
                    : getPermissInfo().co_id,
                  field: 'code',
                  value,
                },
                false
              )
            ).then(({ data }) => {
              if (!data.data) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('角色编号已存在'))
            })
          }
        },
        validateTrigger: 'onBlur',
      },
    ],
    role_name: [
      {
        required: true,
        message: '角色名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getPermissInfo().role_id ? getPermissInfo().role_id : null,
                co_id: getCoId() ? getCoId() : getPermissInfo().co_id,

                field: 'name',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('角色名称已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    role_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })
  const [selectedRowKey, setSelectedRowKey] = useState(null)
  const getRowClassName = (record) => {
    return record.role_id === selectedRowKey ? 'selected-row' : ''
  }
  const userInfo = useSelector((state) => state.login.userInfo)

  // 获取角色列表
  useEffect(() => {
    if (userInfo?.co_type_code === 'ADMIN_GROUP') {
      dispatch(getPermissionList())
    } else {
      dispatch(
        getPermissionList({
          co_id: compId,
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    userInfo?.co_type_code === 'ADMIN_GROUP' &&
      dispatch(getCompListForNameCode())
  }, [dispatch])
  // 获取所有用户列表数据
  useEffect(() => {
    dispatch(getPermissionUserList())
  }, [dispatch])
  // 获取所有角色组织列表数据
  useEffect(() => {
    dispatch(getPermissionOrgList())
  }, [dispatch])
  useEffect(() => {
    dispatch(getUserList())
  }, [dispatch])
  useEffect(() => {
    setTimeout(() => {
      addRef.current?.setFieldsValue({
        ...permissInfo,
        co_id: permissInfo.co_id ? permissInfo.co_id : roleId ? roleId : null,
      })
    }, 0)
  }, [permissInfo, roleId])

  const {
    permissionList,
    total,
    permissionUserList,
    permissionUserTotal,
    permissionOrgList,
    permissionOrgLists,
    permissionOrgTotal,
    permissionMenuList,
    permissionFucList,
    permissionMenuTreeList,
    permissionFuncTreeList,
    permissionUserLists,
  } = useSelector((state) => state.permission)
  const [checkMenu, setCheckMenu] = useState([])
  const [checkFunc, setCheckFunc] = useState([])
  const [defaultOpenMenu, setDefaultOpenMenu] = useState([])
  const [defaultOpenFunc, setDefaultOpenFunc] = useState([])

  const { menuTreeList } = useSelector((state) => state.menu)
  const { fucTreeList } = useSelector((state) => state.fuc)

  const { orgList, total: addOrgTotal } = useSelector((state) => state.org)
  const { userList, total: addUserTotal } = useSelector((state) => state.user)
  const { compListForNameCode } = useSelector((state) => state.comp)
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  // 用户表格数据
  const userColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      ellipsis: {
        showTitle: false,
      },
      render(text, record, index) {
        return (
          <span>
            {(permissUserRef.current.page - 1) *
              permissUserRef.current.page_size +
              index +
              1}
          </span>
        )
      },
    },
    {
      title: '用户名',
      key: 'user',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const { user } = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.username}>
            {user.username}
          </Tooltip>
        )
      },
    },
    {
      title: '手机号',
      dataIndex: 'mobile_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const { user } = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.mobile_no}>
            {user.mobile_no}
          </Tooltip>
        )
      },
    },
    {
      title: '姓名',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.full_name}>
            {user.full_name}
          </Tooltip>
        )
      },
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.nickname}>
            {user.nickname}
          </Tooltip>
        )
      },
    },
    {
      title: '工号',
      dataIndex: 'employee_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.employee_no}>
            {user.employee_no}
          </Tooltip>
        )
      },
    },
    {
      title: '部门',
      dataIndex: '',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee.user }
        return (
          <Tooltip placement="leftTop" title={user.org_name}>
            {user.org_name}
          </Tooltip>
        )
      },
    },
    {
      title: '操作',
      key: 'user',
      width: '80px',
      render(user) {
        return (
          !user?.employee?.is_preset && (
            <>
              <Button
                type="link"
                className="edit"
                onClick={() => showPerUserModal(user)}
              >
                删除
              </Button>
            </>
          )
        )
      },
    },
  ]
  // 添加用户
  const addUserColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 80,
      render(text, record, index) {
        return <span>{(userRef.current.page - 1) * 5 + index + 1}</span>
      },
    },
    {
      title: '用户名',
      dataIndex: 'user',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <Tooltip placement="leftTop" title={text.username}>
          {text.username}
        </Tooltip>
      ),
    },
    {
      title: '手机号',
      dataIndex: 'mobile_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = record.user
        return (
          <Tooltip placement="leftTop" title={user.mobile_no}>
            {user.mobile_no}
          </Tooltip>
        )
      },
    },
    {
      title: '姓名',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '工号',
      dataIndex: 'employee_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '部门',
      key: 'user',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = record.user
        return (
          <Tooltip placement="leftTop" title={user.org_name}>
            {user.org_name}
          </Tooltip>
        )
      },
    },
  ]

  const addOrgColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 80,
      render(text, record, index) {
        return <span>{(orgRef.current.page - 1) * 5 + index + 1}</span>
      },
    },
    {
      title: '部门编号',
      dataIndex: 'org_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '部门名称',
      dataIndex: 'org_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '上级部门',
      dataIndex: 'parent_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '部门描述',
      dataIndex: 'org_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      width: '100px',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '修改时间',
      dataIndex: 'updated_time',
      width: '100px',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
  ]
  // 角色表格数据
  const columns =
    userInfo?.co_type_code === 'ADMIN_GROUP'
      ? [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            ellipsis: {
              showTitle: false,
            },
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },

          {
            title: '企业/产品',
            dataIndex: 'co_name',
            width: 200,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },

          {
            title: '角色编号',
            dataIndex: 'role_code',
            width: 200,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '角色名称',
            dataIndex: 'role_name',
            width: 200,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '角色描述',
            dataIndex: 'role_desc',
            width: 220,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{
                  maxWidth: 560,
                }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '是否管理员',
            dataIndex: 'is_admin',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
            render(is_admin) {
              return is_admin ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '是否预置',
            dataIndex: 'is_preset',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
            render(is_preset) {
              return is_preset ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '包含用户数',
            dataIndex: 'user_count',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'permiss',
            width: 240,
            fixed: 'right',
            render(permiss) {
              if (permiss.is_preset && permiss.is_admin) {
                return (
                  <Space>
                    <Button
                      className="edit"
                      type="link"
                      onClick={() => watchDrawer(permiss)}
                    >
                      查看
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => magDrawer(permiss)}
                    >
                      管理用户
                    </Button>
                  </Space>
                )
              }
              if (permiss.is_preset && !permiss.is_admin) {
                return (
                  <Space>
                    <Button
                      className="edit"
                      type="link"
                      onClick={() => watchDrawer(permiss)}
                    >
                      查看
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => magDrawer(permiss)}
                    >
                      管理用户
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => showPower(permiss)}
                    >
                      授权
                    </Button>
                  </Space>
                )
              }
              if (permiss.is_admin && !permiss.is_preset) {
                return (
                  <Space>
                    <Button
                      className="edit"
                      type="link"
                      onClick={() => watchDrawer(permiss)}
                    >
                      查看
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => magDrawer(permiss)}
                    >
                      管理用户
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => showEdit(permiss)}
                    >
                      修改
                    </Button>
                    <Button
                      type="link"
                      className="del"
                      onClick={() => showDel(permiss)}
                    >
                      删除
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <Button
                    className="edit"
                    type="link"
                    onClick={() => watchDrawer(permiss)}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => magDrawer(permiss)}
                  >
                    管理用户
                  </Button>

                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showPower(permiss)}
                  >
                    授权
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showEdit(permiss)}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDel(permiss)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]
      : [
          {
            title: '序号',
            dataIndex: 'index',
            width: 80,
            render(text, record, index) {
              return (
                <span>
                  {(paramsRef.current.page - 1) * paramsRef.current.page_size +
                    index +
                    1}
                </span>
              )
            },
          },

          {
            title: '角色编号',
            dataIndex: 'role_code',
            width: 200,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '角色名称',
            dataIndex: 'role_name',
            width: 200,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '角色描述',
            dataIndex: 'role_desc',
            width: 220,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip
                overlayStyle={{ maxWidth: 560 }}
                placement="leftTop"
                title={text}
              >
                {text}
              </Tooltip>
            ),
          },
          {
            title: '是否管理员',
            dataIndex: 'is_admin',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
            render(is_admin) {
              return is_admin ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '是否预置',
            dataIndex: 'is_preset',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
            render(is_preset) {
              return is_preset ? (
                <div className="is-true">是</div>
              ) : (
                <div className="is-false">否</div>
              )
            },
          },
          {
            title: '包含用户数',
            dataIndex: 'user_count',
            width: 90,
            ellipsis: {
              showTitle: false,
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '修改时间',
            dataIndex: 'updated_time',
            width: 120,
            ellipsis: {
              showTitle: false,
            },
            render: (text) => (
              <Tooltip placement="leftTop" title={text}>
                {text}
              </Tooltip>
            ),
          },
          {
            title: '操作',
            key: 'permiss',
            width: 240,
            fixed: 'right',
            render(permiss) {
              if (permiss.is_preset && permiss.is_admin) {
                return (
                  <Space>
                    <Button
                      className="edit"
                      type="link"
                      onClick={() => watchDrawer(permiss)}
                    >
                      查看
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => magDrawer(permiss)}
                    >
                      管理用户
                    </Button>
                  </Space>
                )
              }
              if (permiss.is_preset && !permiss.is_admin) {
                return (
                  <Space>
                    <Button
                      className="edit"
                      type="link"
                      onClick={() => watchDrawer(permiss)}
                    >
                      查看
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => magDrawer(permiss)}
                    >
                      管理用户
                    </Button>
                    <Button
                      type="link"
                      className="edit"
                      onClick={() => showPower(permiss)}
                    >
                      授权
                    </Button>
                  </Space>
                )
              }
              return (
                <Space>
                  <Button
                    className="edit"
                    type="link"
                    onClick={() => watchDrawer(permiss)}
                  >
                    查看
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => magDrawer(permiss)}
                  >
                    管理用户
                  </Button>

                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showPower(permiss)}
                  >
                    授权
                  </Button>
                  <Button
                    type="link"
                    className="edit"
                    onClick={() => showEdit(permiss)}
                  >
                    修改
                  </Button>
                  <Button
                    type="link"
                    className="del"
                    onClick={() => showDel(permiss)}
                  >
                    删除
                  </Button>
                </Space>
              )
            },
          },
        ]
  // 部门表格数据
  const orgColumns = [
    {
      title: '序号',
      dataIndex: 'index',
      render(text, record, index) {
        return (
          <span>
            {(permissOrgRef.current.page - 1) *
              permissOrgRef.current.page_size +
              index +
              1}
          </span>
        )
      },
    },
    {
      title: '部门编号',
      dataIndex: 'org_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.org_code}>
            {org.org_code}
          </Tooltip>
        )
      },
    },
    {
      title: '部门名称',
      dataIndex: 'org_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.org_name}>
            {org.org_name}
          </Tooltip>
        )
      },
    },
    {
      title: '上级部门',
      dataIndex: 'parent_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.parent_name}>
            {org.parent_name}
          </Tooltip>
        )
      },
    },
    {
      title: '部门描述',
      dataIndex: 'org_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip
            overlayStyle={{ maxWidth: 560 }}
            placement="leftTop"
            title={org.org_desc}
          >
            {org.org_desc}
          </Tooltip>
        )
      },
    },
    {
      title: '操作',
      key: 'orgInfo',
      width: '80px',
      render: (orgInfo) => {
        return (
          <>
            <Button
              type="link"
              className="edit"
              onClick={() => showPerOrgMoadl(orgInfo)}
            >
              删除
            </Button>
          </>
        )
      },
    },
  ]
  // 查看用户
  const watchUserCol = [
    {
      title: '用户名',
      key: 'user',
      ellipsis: {
        showTitle: false,
      },
      // align: 'center',
      render: (text, record, index) => {
        const { user } = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.username}>
            {user.username}
          </Tooltip>
        )
      },
    },
    {
      title: '手机号',
      dataIndex: 'mobile_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const { user } = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.mobile_no}>
            {user.mobile_no}
          </Tooltip>
        )
      },
    },
    {
      title: '姓名',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.full_name}>
            {user.full_name}
          </Tooltip>
        )
      },
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.nickname}>
            {user.nickname}
          </Tooltip>
        )
      },
    },
    {
      title: '工号',
      dataIndex: 'employee_no',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const user = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.employee_no}>
            {user.employee_no}
          </Tooltip>
        )
      },
    },
    {
      title: '部门',
      dataIndex: 'org_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        const { user } = { ...record.employee }
        return (
          <Tooltip placement="leftTop" title={user.org_name}>
            {user.org_name}
          </Tooltip>
        )
      },
    },
  ]
  // 查看部门
  const watchOrgCol = [
    {
      title: '部门编号',
      dataIndex: 'org_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.org_code}>
            {org.org_code}
          </Tooltip>
        )
      },
    },
    {
      title: '部门名称',
      dataIndex: 'org_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.org_name}>
            {org.org_name}
          </Tooltip>
        )
      },
    },
    {
      title: '上级部门',
      dataIndex: 'parent_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip placement="leftTop" title={org.parent_name}>
            {org.parent_name}
          </Tooltip>
        )
      },
    },
    {
      title: '部门描述',
      dataIndex: 'org_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => {
        const org = record.org
        return (
          <Tooltip
            overlayStyle={{ maxWidth: 560 }}
            placement="leftTop"
            title={org.org_desc}
          >
            {org.org_desc}
          </Tooltip>
        )
      },
    },
  ]
  // 查看菜单
  const watchMenuCol = [
    {
      title: '菜单名称',
      dataIndex: 'menu_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '菜单编号',
      dataIndex: 'menu_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: '菜单描述',
      dataIndex: 'menu_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip
            overlayStyle={{ maxWidth: 560 }}
            placement="leftTop"
            title={text}
          >
            {text}
          </Tooltip>
        )
      },
    },
  ]
  // 查看功能
  const watchFuncCol = [
    {
      title: '功能名称',
      dataIndex: 'func_name',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '功能编号',
      dataIndex: 'func_code',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: '功能描述',
      dataIndex: 'func_desc',
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
  ]

  // 批量删除
  const [batchId, setBatchId] = useState('')
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setBatchId(`${selectedRowKeys}`)
    },
  }
  // 批量删除角色用户
  const [batchUserId, setBatchUserId] = useState('')
  const rowSelectionBatchUser = {
    onChange: (selectedRowKeys, selectedRows) => {
      const BatchUserArr = selectedRows.map((item) => {
        return item.user
      })
      const batchUserArr = BatchUserArr.join(',')
      setBatchUserId(batchUserArr)
    },
  }
  // 批量删除角色部门
  const [batchOrgId, setBatchOrgId] = useState('')
  const rowSelectionBatchOrg = {
    onChange: (selectedRowKeys, selectedRows) => {
      const BatchOrgArr = selectedRows.map((item) => {
        const org = item.org
        return org.org_id
      })
      const batchOrgArr = BatchOrgArr.join(',')
      setBatchOrgId(batchOrgArr)
    },
  }
  // 添加用户
  const [addUser, setAddUser] = useState([])
  const rowSelectionUser = {
    onChange: (selectedRowKeys, selectedRows) => {
      const userArr = selectedRows.map((item) => {
        let users = item.user
        item.role_id = permissInfo.role_id
        item.id = users.id
        return {
          role: item.role_id,
          user: item.id,
        }
      })
      setAddUser(userArr)
    },
  }

  // 添加部门
  const [addOrg, setAddOrg] = useState([])
  const rowSelectionOrg = {
    onChange: (selectedRowKeys, selectedRows) => {
      const orgArr = selectedRows.map((item) => {
        item.role_id = permissInfo.role_id
        item.org = item.org_id
        return {
          role: item.role_id,
          org: item.org,
        }
      })
      setAddOrg(orgArr)
    },
  }

  // 批量新增用户
  const batchAddUsers = async (addUser) => {
    setLoadings(true)
    if (addUser.length === 0) {
      message.error('请至少选择一条数据')
      return
    }
    await dispatch(batchAddUser(addUser, false))
    message.success('添加成功')
    await onSearchUser({ ...userForm.getFieldsValue() })
    await dispatch(
      getPermissionUserList(
        {
          role: permissionId,
        },
        false
      )
    )
    permissUserRef.current.page = 1
    setAddUser([])
    setLoadings(false)
  }
  // 批量新增部门
  const batchAddOrgs = async (orgArr) => {
    setLoadings(true)
    if (orgArr.length === 0) {
      message.error('请至少选择一条数据')
      return
    }
    await dispatch(batchAddOrg(orgArr,false))
    await onSearchOrg({ ...orgForm.getFieldsValue() })
    message.success('添加成功')
    await dispatch(
      getPermissionOrgList({
        role: permissionOrgId,
      },false)
    )
    permissOrgRef.current.page = 1
    setAddOrg([])
    setLoadings(false)
  }

  // 批量删除
  const batchDelPermiss = async (id) => {
    await dispatch(
      batchDelPermission({
        role_id__in: id,
      })
    )
    setBatchDel(false)
    onReset()
    setBatchId('')
    message.success('批量删除成功')
    dispatch(getPermissionList())
  }
  const batchDelPermissUser = async (id) => {
    setLoadings(true)
    await dispatch(
      batchDelUser(
        {
          user__in: id,
        },
        false
      )
    )
    onReset()
    setBatchUserId('')
    message.success('批量删除成功')
    dispatch(
      getPermissionUserList(
        {
          role: permissionId,
        },
        false
      )
    )
    setLoadings(false)
    setBatchUserDel(false)
  }
  const batchDelPermissOrg = async (id) => {
    await dispatch(
      batchDelOrg(
        {
          org__in: id,
        },
        false
      )
    )
    setBatchOrgDel(false)
    onReset()
    setBatchOrgId('')
    message.success('批量删除成功')
    dispatch(
      getPermissionOrgList(
        {
          role: permissionId,
        },
        false
      )
    )
  }
  // 新增角色
  const onFinish = async (values) => {
    if (permissInfo.role_id) {
      const res = await dispatch(
        editPermission({ ...values, id: permissInfo.role_id })
      )
      paramsRef.current.co_id = ''
      if (res.data.code === 0) {
        message.success('修改角色成功')
      } else {
        message.error(res.data.msg)
      }
    } else {
      const res = await dispatch(addPermission(values))
      paramsRef.current.co_id = ''
      if (res.data.code === 0) {
        message.success('新增角色成功')
      } else {
        message.error(res.data.msg)
      }
    }
    setVisible(false)
    paramsRef.current.page = 1

    await dispatch(
      getPermissionList({ ...paramsRef.current, co_id: roleId ? roleId : '' })
    )
    // onReset()
  }
  // 新增弹窗
  const showAdd = () => {
    setPermissInfo({})
    setVisible(true)
  }
  // 修改弹窗
  const showEdit = (per) => {
    per = JSON.parse(JSON.stringify(per))
    setPermissInfo(per)
    setVisible(true)
  }
  // 删除弹窗
  const showDel = (per) => {
    setPermissInfo(per)
    setDelPermis(true)
  }

  // 角色用户删除
  const [perUserId, setPerUserId] = useState('')
  const [delPermisUser, setDelPermisUser] = useState(false)
  const showPerUserModal = (user) => {
    setPerUserId(user.user_role_id)
    setDelPermisUser(true)
  }
  const delPermissUser = async () => {
    await dispatch(batchDelUser({ user_role_id__in: perUserId }, false))
    setDelPermisUser(false)
    message.success('删除角色用户成功')
    dispatch(
      getPermissionUserList(
        {
          role: permissionId,
        },
        false
      )
    )
  }
  // 角色组织删除
  const [perOrgId, setPerOrgId] = useState('')
  const showPerOrgMoadl = (org) => {
    setPerOrgId(org.org_role_id)
    setDelPermisOrg(true)
  }
  const delPermissOrg = async () => {
    await dispatch(batchDelOrg({ org_role_id__in: perOrgId }, false))
    message.success('删除角色部门成功')
    dispatch(
      getPermissionOrgList(
        {
          role: permissionId,
        },
        false
      )
    )
    setDelPermisOrg(false)
  }

  // 查看抽屉
  const watchDrawer = async (per) => {
    per = JSON.parse(JSON.stringify(per))
    await dispatch(
      getPermissionUserLists({
        role: per.role_id,
        is_page: true,
      })
    )
    setPermissInfo(per)
    setWatch(true)
  }
  // 管理抽屉
  const magDrawer = async (per) => {
    console.log(per)
    per = JSON.parse(JSON.stringify(per))
    await dispatch(
      getPermissionUserList({
        role: per.role_id,
        page: 1,
        page_size: 10,
      })
    )

    setPermissInfo(per)
    setPermissionId(per.role_id)
    setUserMag(true)
  }
  const changeTabs = async (key) => {
    console.log(key)
    const boo = !getPermissInfo().is_preset ? false : getPermissInfo().is_preset
    if (key === '2') {
      await dispatch(
        getPermissionOrgLists(
          {
            role: getPermissInfo().role_id,
            is_page: true,
          },
          false
        )
      )
    } else if (key === '3') {
      await dispatch(
        getPermissionMenuTreeList(
          {
            roles: getPermissInfo().role_id,
            co_id: getPermissInfo().co_id,
          },
          false
        )
      )
    } else if (key === '4') {
      await dispatch(
        getPermissionFuncTreeList(
          {
            roles: getPermissInfo().role_id,
            co_id: getPermissInfo().co_id,
          },
          false
        )
      )
    } else if (key === '5') {
      await dispatch(
        getPermissionOrgList(
          {
            role: getPermissInfo().role_id,
            page: 1,
            page_size: 10,
          },
          false
        )
      )
    } else if (key === '6') {
    }
  }
  const [permissionId, setPermissionId] = useState('')
  const showChildrenUserMag = async (per) => {
    await dispatch(
      getUserList(
        {
          exclude_role: per.role_id,
          co_id: per.co_id,
          page: 1,
        },
        false
      )
    )
    setPermissionId(per.role_id)
    setChildrenUserMag(true)
    setLoadings(false)
  }
  const [permissionOrgId, setPermissionOrgId] = useState('')
  const showChildrenOrgMag = async (per) => {
    await dispatch(
      getOrgList(
        {
          exclude_role: per.role_id,
          co_id: per.co_id,
          page: 1,
          ordering: 'org_name',
        },
        false
      )
    )
    setPermissionOrgId(per.role_id)
    setChildrenOrgMag(true)
    setLoadings(false)
  }
  // 删除角色
  const delPermiss = async (id) => {
    await dispatch(delPermission(id))
    // onReset()
    setDelPermis(false)
    message.success('删除角色成功')
    paramsRef.current.page = 1
    paramsRef.current.co_id = ''
    await dispatch(
      getPermissionList({ ...paramsRef.current, co_id: roleId ? roleId : '' })
    )
    // onReset()
  }
  // 搜索
  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  const onSearch = async (values) => {
    setRoleId(values?.co_id)
    paramsRef.current.co_id = values.co_id
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(getPermissionList(paramsRef.current))
  }
  const [searchLoading, setSearchLoading] = useState(false)
  // 搜索用户
  const onSearchUser = async (values) => {
    setSearchLoading(true)
    userRef.current.co_id = permissInfo.co_id
    userRef.current.exclude_role = permissInfo.role_id
    userRef.current.page = 1
    await dispatch(getUserList({ ...values, ...userRef.current }, false))
    setSearchLoading(false)
  }
  // 搜索部门
  const onSearchOrg = async (values) => {
    setLoadings(true)
    orgRef.current.co_id = permissInfo.co_id
    orgRef.current.exclude_role = permissInfo.role_id
    orgRef.current.page = 1
    await dispatch(getOrgList({ ...values, ...orgRef.current }, false))
    setLoadings(false)
  }

  // 角色分页处理
  const onChange = (page, pageSize) => {
    paramsRef.current.co_id = roleId
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    dispatch(getPermissionList(paramsRef.current))
  }
  // 角色用户分页处理
  const permissUserRef = useRef({
    page: 1,
    page_size: 10,
  })
  const onChangePermissionUser = (page, pageSize) => {
    // paramsRef.current.page = page.current
    permissUserRef.current.page = page.current
    permissUserRef.current.page_size = page.pageSize
    permissUserRef.current.role = permissionId
    dispatch(getPermissionUserList(permissUserRef.current))
  }
  // // 角色部门分页处理
  const permissOrgRef = useRef({
    page: 1,
    page_size: 10,
  })
  const onChangePermissionOrg = (page, pageSize) => {
    // paramsRef.current.page = page.current
    permissOrgRef.current.page = page.current
    permissOrgRef.current.page_size = page.pageSize
    permissOrgRef.current.role = permissionId
    dispatch(getPermissionOrgList(permissOrgRef.current))
  }
  // 用户分页处理
  const userRef = useRef({
    page: 1,
    page_size: 5,
  })
  const onChangeUser = (page, pageSize) => {
    userRef.current.page = page.current
    userRef.current.page_size = page.pageSize
    userRef.current.exclude_role = permissInfo.role_id
    userRef.current.co_id = permissInfo.co_id
    dispatch(getUserList(userRef.current, false))
  }
  // 部门分页处理
  const orgRef = useRef({
    page: 1,
    page_size: 5,
  })
  const onChangeOrgs = (page, pageSize) => {
    paramsRef.current.page = page.current
    orgRef.current.page = page.current
    orgRef.current.page_size = page.pageSize
    orgRef.current.exclude_role = permissInfo.role_id
    orgRef.current.co_id = permissInfo.co_id
    dispatch(getOrgList(orgRef.current, false))
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: permissUserRef.current.page,
    total: permissionUserTotal,
    showTotal: (permissionUserTotal) => {
      return `共${permissionUserTotal}条`
    },
  }
  const orgPagination = {
    defaultCurrent: 1,
    defaultPageSize: 10,
    current: permissOrgRef.current.page,
    total: permissionOrgTotal,
    showTotal: (permissionOrgTotal) => {
      return `共${permissionOrgTotal}条`
    },
  }
  const addUserPagination = {
    defaultCurrent: 1,
    defaultPageSize: 5,
    current: userRef.current.page,
    total: addUserTotal,
    showTotal: (addUserTotal) => {
      return `共${addUserTotal}条`
    },
  }
  const addOrgPagination = {
    defaultCurrent: 1,
    defaultPageSize: 5,
    current: orgRef.current.page,
    total: addOrgTotal,
    showTotal: (addOrgTotal) => {
      return `共${addOrgTotal}条`
    },
  }

  // 授权弹窗
  const [powerDrawer, setPowerDrawer] = useState(false)
  const showPower = async (per) => {
    console.log(per)
    setPermissInfo(per)
    per = JSON.parse(JSON.stringify(per))
    // const boo =
    //   per.co_type_code === 'PROD_TEMPLATE' || per.co_type_code === 'ADMIN_GROUP'
    //     ? false
    //     : true
    //如果不是预置直接修改 如果是预置用预置
    const boo = !per.is_preset ? false : per.is_preset
    const res = await dispatch(
      getMenuTreeList(
        {
          roles: per.role_id,
          co_id: per.co_id,
        },
        boo
      )
    )
    setDefaultOpenMenu(
      res?.[0]?.children?.map((item) => {
        return item.key
      })
    )

    await dispatch(
      getMenuPower({
        role_id: per.role_id,
        co_id: per.co_id,
      })
    )
    const res1 = await dispatch(
      getFucTreeList(
        {
          roles: getPermissInfo().role_id,
          co_id: getPermissInfo().co_id,
        },
        boo
      )
    )
    setDefaultOpenFunc(
      res1?.[0]?.children?.map((item) => {
        return item.key
      })
    )
    await dispatch(
      getFucPower({
        role_id: getPermissInfo().role_id,
        co_id: getPermissInfo().co_id,
      })
    )
    setPowerDrawer(true)
  }

  useEffect(() => {
    setCheckMenu(permissionMenuList.map((item) => item.menu))
  }, [permissionMenuList])

  useEffect(() => {
    setCheckFunc(permissionFucList.map((item) => item.func))
  }, [permissionFucList])

  const onCheck = (checkedKeys, info) => {
    setCheckMenu(checkedKeys)
  }

  const onCheckFuc = (checkedKeys, info) => {
    setCheckFunc(checkedKeys)
  }

  // 添加菜单权限
  const addPowers = async () => {
    await dispatch(
      addMenuPower({
        roles: [permissInfo.role_id],
        menus: checkMenu.filter((item) => item !== '全部'),
      })
    )
    await dispatch(
      addFucPower({
        roles: [permissInfo.role_id],
        funcs: checkFunc.filter((item) => item !== '全部'),
      })
    )
    message.success('授权成功')
    setPowerDrawer(false)
  }

  const [form] = Form.useForm()
  const [userForm] = Form.useForm()
  const [orgForm] = Form.useForm()
  const onReset = () => {
    form.resetFields()
    setRoleId('')
  }

  const customExpandIcon = (props) => {
    if (props.record.children.length > 0) {
      if (props.expanded) {
        return (
          <a
            style={{ color: 'black', marginRight: 8 }}
            onClick={(e) => {
              props.onExpand(props.record, e)
            }}
          >
            <MyIcon type="icon-arrowRight-copy" style={{ fontSize: 16 }} />
          </a>
        )
      } else {
        return (
          <a
            style={{ color: 'black', marginRight: 8 }}
            onClick={(e) => {
              props.onExpand(props.record, e)
            }}
          >
            <MyIcon
              type="icon-arrowRight-copy-copy-copy"
              style={{ fontSize: 16 }}
            />
          </a>
        )
      }
    } else {
      return <span style={{ marginRight: 8 }}></span>
    }
  }

  const changeComp = (value) => {
    setCoId(value)
  }

  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>系统管理</Breadcrumb.Item>
            <Breadcrumb.Item>角色管理</Breadcrumb.Item>
          </Breadcrumb>
          <Card className="search-card">
            <Form
              colon={false}
              autoComplete="off"
              form={form}
              className="form-search"
              onFinish={onSearch}
            >
              {/* 'NORMAL' 'SUSPEND' */}
              <div className="flex">
                {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                  <Form.Item label="企业/产品" name="co_id">
                    <Select
                      onChange={changeComp}
                      placeholder="请选择"
                      style={{ width: 238 }}
                      showSearch
                      filterOption={(input, option) => {
                        const children =
                          option?.children?.props?.children?.[0]?.props
                            ?.children?.props?.children
                        return (
                          children.toLowerCase().indexOf(input.toLowerCase()) >=
                          0
                        )
                      }}
                    >
                      {compListForNameCode.map((item) => (
                        <Select.Option value={item.co_id} key={item.co_id}>
                          <div className="myOption">
                            <span className="OptionLeft">
                              <Tooltip title={item.co_name} placement="bottom">
                                {item.co_name}
                              </Tooltip>
                            </span>
                            <span className="OptionRight">{item.co_code}</span>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ''
                )}

                <Form.Item label="角色编号/名称" name="fuzzy_name">
                  <Input
                    placeholder="请输入角色编号或名称"
                    style={{ width: 238 }}
                  ></Input>
                </Form.Item>
              </div>
              <Form.Item>
                <Space size={[20]}>
                  <MyButton type="default" htmlType="submit">
                    查询
                  </MyButton>
                  <MyButton onClick={onReset}>重置</MyButton>
                </Space>
              </Form.Item>
            </Form>
          </Card>
          <Space size={[24]} className="space-heigth">
            <MyButton type="default" onClick={showAdd}>
              + 新增角色
            </MyButton>
            <MyButton
              type="default"
              onClick={() => {
                if (!batchId) {
                  return message.error('请至少勾选一条数据')
                }
                setBatchDel(true)
              }}
            >
              批量删除
            </MyButton>
          </Space>

          <div className="right_table" ref={tableRef}>
            <Table
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    setSelectedRowKey(record?.role_id)
                  }, // 点击行
                }
              }}
              rowClassName={getRowClassName}
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="role_id"
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_preset,
                  }
                },
              }}
              columns={columns}
              dataSource={permissionList}
              pagination={paginations}
            ></Table>
          </div>
        </div>
        {/* 查看角色 */}
        <Drawer
          destroyOnClose={true}
          width={624}
          title="查看角色"
          placement="right"
          onClose={() => setWatch(false)}
          visible={watch}
        >
          <div style={{ minHeight: 'calc(100% - 30px)' }}>
            <div className="msg">
              <span className="linear"></span> 基本信息
            </div>
            <div className="watch_marg">
              {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                <p>
                  <span>企业/产品</span>
                  {permissInfo.co_name || '-'}
                </p>
              ) : (
                ''
              )}
              <p>
                <span>角色编号</span>
                {permissInfo.role_code || '-'}
              </p>
              <p>
                <span>角色名称</span>
                {permissInfo.role_name || '-'}
              </p>
              <p className="desc">
                <span>描述</span>
                <Tooltip
                  overlayStyle={{ maxWidth: 560 }}
                  placement="leftTop"
                  className="desc_text"
                  title={permissInfo.role_desc || '-'}
                >
                  {permissInfo.role_desc || '-'}
                </Tooltip>
              </p>

              <p>
                <span>是否管理员</span>
                {permissInfo.is_admin ? '是' : '否'}
              </p>
            </div>
            <div className="msg">
              <span className="linear"></span> 其他信息
            </div>
            <Tabs
              defaultActiveKey="1"
              onChange={(e) => {
                changeTabs(e)
              }}
            >
              <TabPane tab="用户" key="1">
                <Table
                  className="watch-table"
                  rowKey="co_user_id"
                  columns={watchUserCol}
                  size="small"
                  dataSource={permissionUserLists}
                  pagination={false}
                ></Table>
              </TabPane>
              <TabPane tab="部门" key="2">
                <Table
                  className="watch-table"
                  columns={watchOrgCol}
                  dataSource={permissionOrgLists}
                  rowKey="co_user_id"
                  size="small"
                  pagination={false}
                ></Table>
              </TabPane>
              <TabPane tab="菜单权限" key="3">
                <Table
                  className="watch-table"
                  expandIcon={(props) => customExpandIcon(props)}
                  columns={watchMenuCol}
                  dataSource={permissionMenuTreeList}
                  rowKey="menu_id"
                  size="small"
                  pagination={false}
                ></Table>
              </TabPane>
              <TabPane tab="功能权限" key="4">
                <Table
                  className="watch-table"
                  expandIcon={(props) => customExpandIcon(props)}
                  columns={watchFuncCol}
                  dataSource={permissionFuncTreeList}
                  rowKey="func_id"
                  size="small"
                  pagination={false}
                ></Table>
              </TabPane>
            </Tabs>
          </div>
          <MyButton
            type="default"
            className=" ant-spaces"
            onClick={() => setWatch(false)}
          >
            关闭
          </MyButton>
        </Drawer>
        {/* 授权 */}
        <Drawer
          destroyOnClose={true}
          size="large"
          title="授权"
          placement="right"
          onClose={() => setPowerDrawer(false)}
          visible={powerDrawer}
        >
          <div className="msg">
            <span className="linear"></span> 基本信息
          </div>
          <div className="watch_marg">
            {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
              <p>
                <span>企业/产品</span>
                {permissInfo.co_name}
              </p>
            ) : (
              ''
            )}
            <p>
              <span>角色编号</span>
              {permissInfo.role_code}
            </p>
            <p>
              <span>角色名称</span>
              {permissInfo.role_name}
            </p>
          </div>

          <div className="msg">
            <span className="linear"></span> 权限
          </div>
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => {
              changeTabs(e)
            }}
          >
            <TabPane tab="菜单权限" key="1">
              {menuTreeList?.length > 0 && (
                <Tree
                  key="key"
                  defaultExpandedKeys={defaultOpenMenu}
                  checkable
                  checkedKeys={checkMenu}
                  onCheck={onCheck}
                  treeData={menuTreeList}
                />
              )}

              <div className="flex_end">
                <Space>
                  <MyButton onClick={() => setPowerDrawer(false)}>
                    取消
                  </MyButton>
                  <MyButton type="default" onClick={addPowers}>
                    授权
                  </MyButton>
                </Space>
              </div>
            </TabPane>
            <TabPane tab="功能权限" key="6">
              {fucTreeList?.length > 0 && (
                <Tree
                  key="key"
                  defaultExpandedKeys={defaultOpenFunc}
                  checkable
                  checkedKeys={checkFunc}
                  onCheck={onCheckFuc}
                  treeData={fucTreeList}
                />
              )}

              <div className="flex_end">
                <Space>
                  <MyButton onClick={() => setPowerDrawer(false)}>
                    取消
                  </MyButton>
                  <MyButton type="default" onClick={addPowers}>
                    授权
                  </MyButton>
                </Space>
              </div>
            </TabPane>
          </Tabs>
        </Drawer>

        {/* 管理用户 */}
        <Drawer
          destroyOnClose={true}
          width="75%"
          title="管理用户"
          placement="right"
          onClose={() => setUserMag(false)}
          visible={userMag}
        >
          <div style={{ minHeight: 'calc(100% - 30px)' }}>
            <div className="msg">
              <span className="linear"></span> 基本信息
            </div>
            <div className="watch_marg">
              {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                <p>
                  <span>企业/产品</span>
                  {permissInfo.co_name}
                </p>
              ) : (
                ''
              )}
              <p>
                <span>角色编号</span>
                {permissInfo.role_code}
              </p>
              <p>
                <span>角色名称</span>
                {permissInfo.role_name}
              </p>
            </div>
            <div className="msg">
              <span className="linear"></span> 其他信息
            </div>

            <Tabs
              defaultActiveKey="1"
              onChange={(e) => {
                changeTabs(e)
              }}
            >
              <TabPane tab="用户" key="1">
                <Space size={[24]} className="space-heigth">
                  <MyButton
                    loading={loadings}
                    type="default"
                    onClick={() => {
                      setLoadings(true)
                      showChildrenUserMag(permissInfo)
                    }}
                  >
                    {loadings ? '' : '+ 添加用户'}
                  </MyButton>
                  <MyButton
                    type="default"
                    onClick={() => {
                      if (!batchUserId)
                        return message.error('请至少勾选一条数据')
                      setBatchUserDel(true)
                    }}
                  >
                    批量删除
                  </MyButton>
                </Space>
                <Table
                  rowSelection={{ ...rowSelectionBatchUser }}
                  rowKey={(i) => {
                    return i.user_role_id
                  }}
                  onChange={onChangePermissionUser}
                  size="small"
                  dataSource={permissionUserList}
                  pagination={pagination}
                  columns={userColumns}
                ></Table>
              </TabPane>
              <TabPane tab="部门" key="5">
                <Space size={[24]} className="space-heigth">
                  <MyButton
                    loading={loadings}
                    type="default"
                    onClick={() => {
                      setLoadings(true)
                      showChildrenOrgMag(permissInfo)
                    }}
                  >
                    {loadings ? '' : '+ 添加部门'}
                  </MyButton>
                  <MyButton
                    type="default"
                    onClick={() => {
                      if (!batchOrgId)
                        return message.error('请至少勾选一条数据')
                      setBatchOrgDel(true)
                    }}
                  >
                    批量删除
                  </MyButton>
                </Space>
                <Table
                  rowKey={(org) => {
                    return org.org_role_id
                  }}
                  rowSelection={{ ...rowSelectionBatchOrg }}
                  onChange={onChangePermissionOrg}
                  pagination={orgPagination}
                  dataSource={permissionOrgList}
                  columns={orgColumns}
                  size="small"
                ></Table>
              </TabPane>
            </Tabs>
          </div>
          <Space size={[24]} className="ant-spaces">
            <MyButton type="default" onClick={() => setUserMag(false)}>
              关闭
            </MyButton>
          </Space>

          {/* 批量删除角色部门对话框 */}
          <Modal
            bodyStyle={{ textAlign: 'center' }}
            centered
            title="批量删除部门"
            visible={batchOrgDel}
            getContainer={false}
            okText="删除"
            footer={[
              <Space size={[24]} className="model-btn">
                <MyButton onClick={() => setBatchOrgDel(false)}>取消</MyButton>
                <MyButton
                  type="default"
                  onClick={() => batchDelPermissOrg(batchOrgId)}
                >
                  删除
                </MyButton>
              </Space>,
            ]}
          >
            <p>删除角色的部门后将取消授予用户的部门</p>
            <p>确定要删除所选部门吗？</p>
          </Modal>
          {/* 删除角色部门对话框 */}
          <Modal
            bodyStyle={{ textAlign: 'center' }}
            centered
            onCancel={() => setDelPermisOrg(false)}
            onOk={delPermissOrg}
            title="删除角色部门"
            visible={delPermisOrg}
            getContainer={false}
            okText="删除"
          >
            <p>删除角色的部门后将取消授予用户的部门</p>
            <p>确定要删除该角色的部门吗？</p>
          </Modal>
          {/* 批量删除角色用户对话框 */}
          <Modal
            bodyStyle={{ textAlign: 'center' }}
            centered
            title="批量删除角色用户"
            visible={batchUserDel}
            getContainer={false}
            okText="删除"
            footer={[
              <Space size={[24]} className="model-btn">
                <MyButton onClick={() => setBatchUserDel(false)}>取消</MyButton>
                <MyButton
                  loading={loadings}
                  type="default"
                  onClick={() => batchDelPermissUser(batchUserId)}
                >
                  删除
                </MyButton>
              </Space>,
            ]}
          >
            <p>删除角色的用户后将取消授予用户的角色权限</p>
            <p>确定要删除所选用户吗？</p>
          </Modal>

          {/* 删除角色用户对话框 */}
          <Modal
            bodyStyle={{ textAlign: 'center' }}
            centered
            onCancel={() => setDelPermisUser(false)}
            onOk={() => delPermissUser()}
            title="删除角色用户"
            visible={delPermisUser}
            getContainer={false}
            okText="删除"
          >
            <p>删除角色的用户后将取消授予用户的角色权限</p>
            <p>确定要删除该角色的用户吗？</p>
          </Modal>
        </Drawer>
        {/* 添加用户 */}
        <Modal
          destroyOnClose={true}
          onCancel={() => {
            setChildrenUserMag(false)
            userRef.current.page = 1
          }}
          centered
          width="984px"
          minHeight="560px"
          // height="560px"
          bodyStyle={{ textAlign: 'center' }}
          title="添加用户"
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton
                onClick={() => {
                  setChildrenUserMag(false)
                  userRef.current.page = 1
                  userForm.resetFields()
                }}
              >
                关闭
              </MyButton>
              <MyButton
                loading={loadings}
                type="default"
                onClick={() => batchAddUsers(addUser)}
              >
                添加
              </MyButton>
            </Space>,
          ]}
          visible={childrenUserMag}
        >
          <Form
            colon={false}
            form={userForm}
            autoComplete="off"
            className="form-flex"
            onFinish={onSearchUser}
          >
            <Form.Item label="归属部门" name="org_name">
              <Input placeholder="请输入部门名称"></Input>
            </Form.Item>
            <Form.Item label="关键字" name="fuzzy_name">
              <Input placeholder="请输入关键字"></Input>
            </Form.Item>
            <Form.Item>
              <Space size={[20]}>
                <MyButton
                  loading={searchLoading}
                  htmlType="submit"
                  type="default"
                >
                  查询
                </MyButton>
                <MyButton
                  onClick={() => {
                    userForm.resetFields()
                  }}
                >
                  重置
                </MyButton>
              </Space>
            </Form.Item>
          </Form>
          <Table
            rowKey="co_user_id"
            onChange={onChangeUser}
            rowSelection={{ ...rowSelectionUser }}
            size="small"
            columns={addUserColumns}
            dataSource={userList}
            pagination={addUserPagination}
          ></Table>
        </Modal>
        {/* 添加部门 */}
        <Modal
          destroyOnClose={true}
          centered
          width="984px"
          minHeight="560px"
          bodyStyle={{ textAlign: 'center' }}
          title="添加部门"
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton
                onClick={() => {
                  setChildrenOrgMag(false)
                  orgRef.current.page = 1
                  orgForm.resetFields()
                }}
              >
                关闭
              </MyButton>
              <MyButton
                loading={loadings}
                type="default"
                onClick={() => batchAddOrgs(addOrg)}
              >
                添加
              </MyButton>
            </Space>,
          ]}
          onCancel={() => {
            setChildrenOrgMag(false)
            orgRef.current.page = 1
          }}
          visible={childrenOrgMag}
        >
          <Form
            colon={false}
            form={orgForm}
            autoComplete="off"
            className="form-flex"
            onFinish={onSearchOrg}
          >
            <Form.Item label="关键字" name="fuzzy_name">
              <Input placeholder="请输入部门名称或编号"></Input>
            </Form.Item>
            <Form.Item>
              <Space size={[20]}>
                <MyButton loading={loadings} htmlType="submit" type="default">
                  查询
                </MyButton>
                <MyButton
                  onClick={() => {
                    orgForm.resetFields()
                  }}
                >
                  重置
                </MyButton>
              </Space>
            </Form.Item>
          </Form>
          <Table
            rowKey="org_id"
            onChange={onChangeOrgs}
            rowSelection={{ ...rowSelectionOrg }}
            size="small"
            columns={addOrgColumns}
            dataSource={orgList}
            pagination={addOrgPagination}
          ></Table>
        </Modal>
        {/* 新增角色 */}
        <Drawer
          width={624}
          destroyOnClose={true}
          size="large"
          maskClosable={false}
          title={permissInfo.role_id ? '修改角色' : '新增角色'}
          placement="right"
          onClose={() => {
            setVisible(false)
          }}
          visible={visible}
        >
          <Form
            colon={false}
            autoComplete="off"
            ref={addRef}
            onFinish={onFinish}
            validateTrigger={['onBlur', 'onChange']}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            initialValues={{
              is_admin: false,
            }}
          >
            <div className="form-btn">
              <div className="flex">
                {userInfo?.co_type_code === 'ADMIN_GROUP' ? (
                  <Form.Item
                    label="企业/产品"
                    name="co_id"
                    className="w-50"
                    rules={rulesObj.current.co_id}
                  >
                    <Select
                      onChange={() => {
                        console.log(addRef)
                        changeComp()
                        addRef.current.validateFields(['role_code'])
                      }}
                      disabled={permissInfo.role_id ? true : false}
                      filterOption={(input, option) => {
                        const children =
                          option?.children?.props?.children?.[0]?.props
                            ?.children
                        return (
                          children.toLowerCase().indexOf(input.toLowerCase()) >=
                          0
                        )
                      }}
                      showSearch
                      placeholder="请选择"
                    >
                      {compListForNameCode.map((item) => (
                        <Select.Option value={item.co_id} key={item.co_id}>
                          <div className="myOption">
                            <span className="OptionLeft">
                              <Tooltip title={item.co_name} placement="bottom">
                                {item.co_name}
                              </Tooltip>
                            </span>
                            <span className="OptionRight">{item.co_code}</span>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  ''
                )}

                <Form.Item
                  rules={rulesObj.current.role_code}
                  label="角色编号"
                  name="role_code"
                  className="w-50"
                >
                  <Input
                    maxLength={50}
                    placeholder="请输入角色编号，不超过50个字"
                  ></Input>
                </Form.Item>
              </div>

              <Form.Item
                rules={rulesObj.current.role_name}
                label="角色名称"
                name="role_name"
              >
                <Input maxLength={50} placeholder="不超过50个字"></Input>
              </Form.Item>

              <Form.Item
                label="角色描述"
                name="role_desc"
                rules={rulesObj.current.role_desc}
              >
                <Input.TextArea
                  maxLength={500}
                  showCount
                  placeholder="不超过500个字"
                  style={{ height: '100px' }}
                ></Input.TextArea>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.co_id !== currentValues.co_id
                }
              >
                {({ getFieldValue }) => {
                  let formal_corp = getFieldValue('co_id')
                  const obj = compListForNameCode?.find(
                    (item) => item.co_id === formal_corp
                  )
                  if (obj?.co_type_code === 'FORMAL_CORP') {
                    return (
                      <Form.Item label="是否管理员" name="is_admin">
                        <Radio.Group value={radio}>
                          <Radio value={true}>是</Radio>
                          <Radio value={false}>否</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )
                  }
                }}
              </Form.Item>
            </div>
            <Form.Item>
              <div className="flex_end">
                <Space>
                  <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                  <MyButton type="default" htmlType="submit">
                    {permissInfo.role_id ? '修改' : '新增'}
                  </MyButton>
                </Space>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
        {/* 删除角色对话框 */}
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          centered
          onCancel={() => setDelPermis(false)}
          title="删除角色"
          visible={delPermis}
          getContainer={false}
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setDelPermis(false)}>取消</MyButton>
              <MyButton
                type="default"
                onClick={() => delPermiss(permissInfo.role_id)}
              >
                删除
              </MyButton>
            </Space>,
          ]}
        >
          <p>
            <MyIcon
              style={{ fontSize: 40 }}
              type="icon-a-gaojingshixin1"
            ></MyIcon>
          </p>
          <p>删除角色后将取消授予用户的角色权限</p>
          <p>确定要删除角色"{permissInfo.role_name}"吗？</p>
        </Modal>
        {/* 批量删除角色对话框 */}
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          onCancel={() => setBatchDel(false)}
          centered
          title="批量删除角色"
          visible={batchDel}
          getContainer={false}
          footer={[
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setBatchDel(false)}>取消</MyButton>
              <MyButton type="default" onClick={() => batchDelPermiss(batchId)}>
                删除
              </MyButton>
            </Space>,
          ]}
        >
          <p>
            <MyIcon
              style={{ fontSize: 40 }}
              type="icon-a-gaojingshixin1"
            ></MyIcon>
          </p>
          <p>删除角色后将取消授予用户的角色权限</p>
          <p>确定要删除所选角色吗？</p>
        </Modal>
      </div>
    </ConfigProvider>
  )
}
