//判断用户状态
export const judgingStatus = (status) => {
    if (status === 'COMPANY_NOT_LICENSE') {
        //未开通任何服务
        return 1
    } else if (status === 'COMPANY_APPLYING') {
        //企业审核中
        return 2
    } else if (status === 'COMPANY_NONE') {
        //未创建或加入企业
        return 3
    }
    return 0
}