import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Empty,
  ConfigProvider,
  Upload,
  Modal,
  message,
} from "antd";
import zhCH from "antd/lib/locale/zh_CN";
import MyButton from "../../../../components/MyButton";
import img from "../../../../assets/暂无icon@2x.png";
import img2 from "../../../../assets/01-b.png";
import img3 from "../../../../assets/02-b.png";
import api from "../../../../api";
import SubCard from "../../../SaasLayout/ProductSubscription/SubCard";

export default function License() {
  const [licenseList, setLicenseList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [esn, setEsn] = useState("");

  const getEsnList = () => {
    api.getEsnList().then((res) => {
      if (res.data?.code === 0) {
        setEsn(res.data.data?.ESN || "");
      } else {
        message.error(res.data.msg);
        setEsn("");
      }
    });
  };

  const getSubsPlan = () => {
    api.getSubsPlan().then((res) => {
      console.log("getSubsPlan", res);
      if (res.data?.code === 0) {
        setLicenseList(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setLicenseList([]);
      }
    });
  };

  const exeCommandCopyText = (text) => {
    try {
      const t = document.createElement("textarea");
      t.nodeValue = text;
      t.value = text;
      document.body.appendChild(t);
      t.select();
      document.execCommand("copy");
      document.body.removeChild(t);
      message.success("复制成功");
      return true;
    } catch (e) {
      console.log(e);
      message.error("复制失败");
      return false;
    }
  };

  useEffect(() => {
    getEsnList();
    getSubsPlan();
  }, []);

  const onChange = ({ file, fileList }) => {
    const formData = new FormData();
    formData.append("file", file?.originFileObj);
    api.upLicense(formData).then((res) => {
      console.log(res);
      if (res.data.code === 0) {
        getSubsPlan();
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <ConfigProvider locale={zhCH}>
      <div className={styles.root}>
        <div className="right_content">
          <Breadcrumb>
            <Breadcrumb.Item>系统管理</Breadcrumb.Item>
            <Breadcrumb.Item>软件许可</Breadcrumb.Item>
          </Breadcrumb>

          <div className="cardBox">
            <div className="btnBox">
              <MyButton
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                申请软件许可
              </MyButton>
              <Upload onChange={onChange} fileList={[]} accept=".key">
                <MyButton type="primary">导入许可文件</MyButton>
              </Upload>
            </div>
            {licenseList?.length > 0 ? (
              <div className="licenseListBox">
                {licenseList?.map((item,index) => (
                  <SubCard
                    max={6}
                    deleteCallBack={getSubsPlan}
                    deleteIcon={true}
                    noBtn={true}
                    style={{ height: "50%" }}
                    key={index}
                    info={item}
                  ></SubCard>
                ))}
              </div>
            ) : (
              <div className="noLicenseBox">
                <Empty
                  image={img}
                  description="还未导入许可文件，请点击“申请软件许可”按说明申请软件许可文件后导入"
                />
              </div>
            )}
          </div>
        </div>
        <Modal
          title="申请软件许可"
          centered={true}
          footer={null}
          visible={isModalOpen}
          onCancel={handleCancel}
          wrapClassName="licenseModal"
        >
          <div className="licenseModalContent">
            <div className="licenseTitle">
              <img src={img2} alt="" />
              申请软件许可
            </div>
            <div className="licenseText">
              请将下列ESN码提供给销售经理，申请许可文件。
            </div>
            <div className="licenseEsnText">
              {esn}
              <span
                onClick={() => {
                  exeCommandCopyText(esn);
                }}
              >
                复制ESN码
              </span>
            </div>
            <div className="licenseTitle">
              <img src={img3} alt="" />
              导入软件许可
            </div>
            <div className="licenseText">
              销售经理提供授权文件后，点击“导入许可文件”按钮，导入许可文件。
            </div>
          </div>
        </Modal>
      </div>
    </ConfigProvider>
  );
}
