//获取 智能客服完整网址
export const initUrl = (href, prd_name, userInfo) => {
    // href:原始网址
    // prd_name: 项目名
    // userInfo: 用户信息
    let params = {
      project_name: prd_name || "导航页",
      co_name: userInfo?.co_name,
      co_id: userInfo?.co_id,
      user_name: userInfo?.show_name,
      user_id: userInfo?.co_user_id,
    };
    let _href = href + "?info=" + window?.encodeURIComponent(JSON.stringify(params));
    console.log(_href,href, prd_name, userInfo,params);
    return _href;
  };