import React, { useEffect, useState } from 'react'
import Api from '../../../api/index'
import { Form, Input, Button, message } from 'antd'
import useUrlState from '@ahooksjs/use-url-state'
import styles from './index.module.scss'
import gouxuan from '../../images/gouxuan.png'
import { useDispatch } from 'react-redux'
import { joinComp } from '../../../store/actions/permission'
import { setToken } from '../../../utils/cookie'
import { editWechatInfo } from '../../../store/actions/user'
import { closeWin } from '../../../utils/closewin'
export default function BindWxPage() {
  const dispatch = useDispatch()
  const [state, setState] = useUrlState({})
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)
  const [coName, setCoName] = useState(null)

  const getUserInfo = async (params) => {
    console.log(params)
    const res = await dispatch(editWechatInfo(params))
    console.log(res)
    if (res.data.code === 0) {
      message.success('绑定成功')
      setTimeout(() => {
        closeWin()
      }, 2000)
    } else {
      message.error(res.data.msg)
    }
  }
  useEffect(() => {
    document.title = '授权绑定'
    //获取用户信息
    console.log(state)
    let params = {}
    const id = window.location.pathname?.slice(12)?.split('/')[0] || null
    const unique_key = window.location.pathname?.split('/').pop()
    params.user_id = id
    params.code = state.code
    params.is_bind = true
    params.unique_key = unique_key || null
    if (state.code) {
      getUserInfo(params)
    }
  }, [])
  return (
    <div className={styles.root}>
      <div className="content_div" id="conId"></div>
    </div>
  )
}
