import request from '../../utils/request'
import { GETUPDATELOGLIST, GETCURRENTUPDATELOGLIST } from '../constants'

export const getUpdateLogList = (params) => {
  return async (dispatch) => {
    const res = await request.get('/sys/log/updates/', { params })
    dispatch({
      type: GETUPDATELOGLIST,
      payload: res.data.data,
    })
  }
}
export const getCurrentLogList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('/sys/log/updates/', { params, isLoading })
    dispatch({
      type: GETCURRENTUPDATELOGLIST,
      payload: res.data.data,
    })
  }
}

export const addUpdates = (values) => {
  return async (dispatch) => {
    const res = await request.post('/sys/log/updates/', values)
    console.log(res)
  }
}

export const editUpdates = (values) => {
  console.log(values)
  return async (dispatch) => {
    await request.put(`/sys/log/updates/${values.update_log_id}/`, values)
  }
}

export const deleteUpdates = (sys_id) => {
  return async (dispatch) => {
    await request.delete(`/sys/log/updates/${sys_id}/`)
  }
}
