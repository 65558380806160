import { GETPRODUCTCFGTEMP } from '../constants'

const init = {
  total: 0,
  productCfgTempList: [],
}

export default function getProductTemp(state = init, action) {
  if (action.type === GETPRODUCTCFGTEMP) {
    return {
      ...state,
      productCfgTempList: action.payload.data,
      total: action.payload.total,
    }
  }
  return state
}
