import { GETMENULIST, GETMENUTREELIST } from '../constants'

const init = {
  menuTreeList: [],
  menuList: [],
  menuAllList: [],
  total: 0,
}
export default function getMenuTreeList(state = init, action) {
  if (action.type === GETMENUTREELIST) {
    return {
      ...state,
      menuTreeList: action.payload,
    }
  }
  if (action.type === GETMENULIST) {
    return {
      ...state,
      menuList: action.payload.data,
      total: action.payload.total,
      menuAllList: action.payload,
    }
  }
  return state
}
