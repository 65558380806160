console.log('加载了Util')
//在后续的wps版本中，wps的所有枚举值都会通过wps.Enum对象来自动支持，现阶段先人工定义
var WPS_Enum = {
  msoCTPDockPositionLeft: 0,
  msoCTPDockPositionRight: 2,
}

/**
 * 通过wps提供的接口执行一段脚本
 * @param {*} param 需要执行的脚本
 */
function GetUrlPath() {
  let e = document.location.toString()
  e = decodeURI(e)
  if (-1 !== e.indexOf('/')) e = e.substring(0, e.lastIndexOf('/'))
  return e
}

export const judgingCharacters = (str, max) => {
  let num = 0
  for (let i = 0; i < str?.length; i++) {
    let a = str[i]
    const reg = /[\u4e00-\u9fa5]/g
    if (a.match(reg)) {
      num += 2
    } else {
      num += 1
    }
    if (num > max) {
      return false
    }
  }
  return true
}
