import { Button } from 'antd'

export function MyButton(props) {
  // console.log(props)

  let bgcolor = props.bgcolor
  const color = props.type == undefined ? '#535353' : '#fff'
  const height = 32
  const width = props.width ? props.width : 96
  const fontSize = 14
  const fontWeight = 400
  const borderColor = '#D8D8D8'

  if (bgcolor == undefined) {
    bgcolor = props.type !== undefined ? '#016DB4' : '#fff'
  }

  const styleObj = {
    backgroundColor: bgcolor,
    color,
    borderColor: bgcolor,
    width: width,
    height: height,
    fontSize: fontSize,
    fontWeight: fontWeight,
    borderColor: borderColor,
    marginLeft: props.ml,
    marginRight: props.mr,
  }
  return (
    <Button loading={props.loading} {...props} style={styleObj}>
      {props.children}
    </Button>
  )
}

export default MyButton
