import React from "react";
import styles from "./index.module.scss";
import api from "../../api";
import { useEffect, useRef, useState } from "react";
import { message, Drawer, Input, Popover, Avatar } from "antd";
import img from "../../assets/noProject.png";
import img2 from "../../assets/noProject2.png";
import {
  getAdmin,
  setToken,
  setSuperGroup,
  setUserStatus,
  setCallbackUrl,
} from "../../utils/cookie";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../components/MyButton";
import { CloseOutlined } from "@ant-design/icons";
import { useGetState } from "ahooks";
import { useHistory } from "react-router";
import { getMenuOfPersonal, getUserInfo } from "../../store/actions/login";
import { judgingStatus } from "../../utils/judgingStatus";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";

export default function NoProject() {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [admins, setAdmins] = useState([]);
  const userInfo = useSelector((state) => state.login.userInfo);
  const [open, setOpen] = useState(false);
  const [enterpriseName, setEnterpriseName, getEnterpriseName] =
    useGetState("");
  const [bindSuccess, setBindSuccess, getBindSuccess] = useGetState(false);
  const history = useHistory();
  const [state, setState] = useUrlState({});
  const [licenseList, setLicenseList, getLicenseList] = useGetState([]);
  const [prdCover, setPrdCover, getPrdCover] = useGetState([]);
  const [menus, setMenus, getMenus] = useGetState([]);
  const [project, setProject, getProject] = useGetState(null);
  const [showPlan, setShowPlan, getShowPlan] = useGetState(false);

  const getSubsPlan = () => {
    let a = api.getSubsPlan();
    let b = api.getPrdCover();
    let c = api.getMenu();
    Promise.all([a, b, c]).then((result) => {
      let [res, res2, res3] = result;
      const menuList = res3?.data?.data || [];
      setPrdCover(res2?.data?.data || []);
      setLicenseList(res.data.data || []);
      setMenus(menuList);
      const _menuList = menuList?.filter((_) => _.menu_url);
      if (_menuList?.length === 0) {
        //没有订阅过不处理
      } else {
        //先判断是哪个产品跳过来的
        if (state.project) {
          let item = null;
          //找到对应的产品的 订阅方案
          item = _menuList?.find((_) => _?.menu_code?.includes(state.project));
          //若有跳到对应的产品
          if (item) {
            // console.log(item);
            window.location.href = item?.menu_url;
          }
        } else {
          //没有任何产品信息 跳到首页
          history.push("/home");
          dispatch(getUserInfo());
        }
      }
    });
  };

  const getProducts = () => {
    api.getProducts().then((res) => {
      console.log(res);
      if (res.data?.code === 0) {
        setProducts(res.data.data || []);
      } else {
        message.error(res.data.msg);
        setProducts([]);
      }
    });
  };

  const getAdminFunc = () => {
    api.getAdmin().then((res) => {
      console.log(res);
      if (res.data?.code === 0) {
        setAdmins(res.data.data);
      } else {
        message.error(res.data.msg);
        setAdmins([]);
      }
    });
  };

  useEffect(() => {
    document.title = "无项目";
    const project = state.project;
    setProject(project);
    getSubsPlan();
    getProducts();
    getAdminFunc();
  }, []);

  const initMsgDom = () => {
    const admin = getAdmin();
    const status = judgingStatus(userInfo.status);
    let title = "",
      text = "",
      spanText = "";
    if (getBindSuccess()) {
      title = "恭喜您创建组织/企业成功！";
      text = "您现在可以开通服务啦！";
      spanText = "（您也可以稍后在吾道产品介绍页面开通服务）";
    } else if (status === 1) {
      //未开通任何服务
      title = "您的企业/组织还未开通产品服务！";
      if (admin) {
        text = "建议您现在开通产品服务！";
        spanText = "（您也可以稍后在吾道产品介绍页面开通服务）";
      } else {
        text = (
          <>
            建议您联系
            <Popover
              placement="bottomLeft"
              title={null}
              // visible={true}
              overlayClassName="adminPopover"
              content={
                <div className="adminPopoverContent">
                  {admins?.map((item) => (
                    <div className="adminPopoverItem">
                      <span>
                        <Avatar
                          style={{ marginRight: "8px" }}
                          size={24}
                          src={item?.pf_photo}
                        />
                        {item?.show_name}
                      </span>
                      <span>{item?.assist_name}</span>
                    </div>
                  ))}
                </div>
              }
            >
              <a className="adminBox">管理员</a>
            </Popover>
            开通产品服务！
          </>
        );
        spanText = "（您可以先了解一下我们的产品服务）";
      }
    } else if (status === 2) {
      //企业审核中
      title = "您提交的加入企业/组织申请正在审核中！";
      text = "建议您完善个人信息，以方便使用吾道产品。";
      spanText = "（您也可以稍后在个人设置中完善和修改个人信息）";
    } else if (status === 3) {
      //未创建或加入企业
      title = "您还未创建或加入企业/组织！";
      text = "您可以现在创建企业或组织，并邀请同事加入！";
    } else if (getProject()) {
      title = "您的企业/组织还未开通此产品服务！";
      text = (
        <>
          建议您联系
          <Popover
            placement="bottomLeft"
            title={null}
            // visible={true}
            overlayClassName="adminPopover"
            content={
              <div className="adminPopoverContent">
                {admins?.map((item) => (
                  <div className="adminPopoverItem">
                    <span>
                      <Avatar
                        style={{ marginRight: "8px" }}
                        size={24}
                        src={item?.pf_photo}
                      />
                      {item?.show_name}
                    </span>
                    <span>{item?.assist_name}</span>
                  </div>
                ))}
              </div>
            }
          >
            <a className="adminBox">管理员</a>
          </Popover>
          开通产品服务！
        </>
      );
      spanText = "（您可以先了解一下我们的产品服务）";
    }

    if (getProject() && status === 0) {
      const flag = getLicenseList()?.some((__) =>
        __?.prd_code?.includes(getProject())
      );
      return (
        <>
          <div className="prodMsgText">
            <div className="prodMsgTextTitle">
              {flag ? "当前没有访问" : "当前还未订阅"}{" "}
              <span>
                {
                  getPrdCover()?.find((_) => _.prd_code?.includes(getProject()))
                    ?.prd_name
                }
              </span>{" "}
              {flag ? "产品权限" : "产品"}！
            </div>
            <>
              建议您联系
              <Popover
                placement="bottomLeft"
                title={null}
                // visible={true}
                overlayClassName="adminPopover"
                content={
                  <div className="adminPopoverContent">
                    {admins?.map((item) => (
                      <div className="adminPopoverItem">
                        <span>
                          <Avatar
                            style={{ marginRight: "8px" }}
                            size={24}
                            src={item?.pf_photo}
                          />
                          {item?.show_name}
                        </span>
                        <span>{item?.assist_name}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <a className="adminBox">管理员</a>
              </Popover>
              {flag ? "开通权限" : "开通产品订阅"} 或 访问其他产品
              {flag ? null : <span>{spanText}</span>}
            </>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="userMsgTitle">{title}</div>
        {!getBindSuccess() && status === 2 && (
          <div className="userMsgText">您可以先了解一下我们的产品服务！</div>
        )}
        <div className="userMsgText">
          {text}
          <span>{spanText}</span>
          {status === 3 && (
            <MyButton
              bgcolor="#16D8D8"
              type="112312"
              onClick={() => {
                setEnterpriseName("");
                setTimeout(() => {
                  setOpen(true);
                }, 100);
              }}
            >
              创建企业
            </MyButton>
          )}
          {status === 2 && (
            <a
              href="/saas/personalsettings"
              target="_break"
              onClick={() => {
                const href = window.location;
                setCallbackUrl(href)
              }}
            >
              点击完善个人信息
            </a>
          )}
        </div>
        {getBindSuccess() && (
          <div className="userMsgText">
            开通服务后您可以邀请同事加入企业啦!
            <span>（您也可以稍后在企业管理中邀请同事加入）</span>
            <a href="/saas/businessmanagement/membermanagement" target="_break">
              点击邀请成员
            </a>
          </div>
        )}
        {getBindSuccess() && (
          <div className="userMsgText">
            建议您完善个人信息，以方便使用吾道产品。
            <span>（您也可以稍后在个人设置中完善和修改个人信息）</span>
            <a
              href="/saas/personalsettings"
              target="_break"
              onClick={() => {
                const href = window.location;
                setCallbackUrl(href)
              }}
            >
              点击完善个人信息
            </a>
          </div>
        )}
        {!getBindSuccess() && status === 3 && (
          <div className="userMsgText">
            如果您的企业或组织已经创建，可以联系企业管理员邀请您加入企业!
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    const status = judgingStatus(userInfo.status);
    setShowPlan(status === 0);
  }, [userInfo]);

  const bindEnterprise = () => {
    if (!getEnterpriseName()) {
      message.error("请输入组织或企业名称");
      return;
    }
    api.bindEnterprise(enterpriseName).then((res) => {
      console.log(res);
      if (res.data.code === 0) {
        api.switchEnterprise("", res.data.data?.company_id).then((res2) => {
          console.log(
            "🚀 ~ file: register.jsx:63 ~ Api.用户切换企业 ~ res2:",
            res2
          );
          if (res2.data.code === 0) {
            setToken(res2.data.data?.token || "");
            setSuperGroup(res2.data.data?.super_group || false);
            setUserStatus(1);
            dispatch(getUserInfo());
            dispatch(getMenuOfPersonal());
            setBindSuccess(true);
            setOpen(false);
          } else {
            message.error(res2.data.meg);
          }
        });
      } else {
        message.error(res.data.msg);
      }
    });
  };

  return (
    <div className={styles["root"]}>
      <div className="content">
        <div>
          <div
            className={
              showPlan ? "userMsgBox showPlanUserMsgBox" : "userMsgBox"
            }
          >
            <img src={getBindSuccess() ? img2 : img} alt="" />
            <div className="userMsg">{initMsgDom()}</div>
          </div>

          {showPlan && <div className="productTitle">已订阅</div>}

          {showPlan && (
            <div className="licenseListBox">
              {licenseList?.map((_) => {
                let type = _.subs_plan_name;
                let prd = _.prd_name;
                if (
                  _.subs_plan_name?.includes("（") &&
                  _.subs_plan_name?.includes("）")
                ) {
                  let [a, b] = _.subs_plan_name?.split("（");
                  type = a;
                  prd = b?.slice(0, -1);
                }
                return (
                  <div
                    className="licenseItemBox"
                    style={{ width: "130px" }}
                    onClick={() => {
                      window.open(_?.product_url, _?.prd_code);
                    }}
                  >
                    <svg
                      fill="none"
                      version="1.1"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      className="topIcon"
                    >
                      <defs>
                        <linearGradient
                          x1="0"
                          y1="1"
                          x2="1.0152994394302368"
                          y2="0.3041720986366272"
                          id="master_svg0_547_04143"
                        >
                          <stop
                            offset="0%"
                            stop-color="#FF91A6"
                            stop-opacity="1"
                          />
                          <stop
                            offset="100%"
                            stop-color="#FFC683"
                            stop-opacity="1"
                          />
                        </linearGradient>
                      </defs>
                      <g>
                        <g>
                          <path
                            d="M0 0C0 0 0 0 0 0L13 0C15.209138999323173 0 17 1.7908610006768266 17 4L17 16C17 16 17 16 17 16L4 16C1.7908610006768266 16 0 14.209138999323173 0 12Z"
                            fill="currentColor"
                            fill-opacity="1"
                          />
                        </g>
                        <g>
                          <g>
                            <path
                              d="M7.379160000000001,4.00011728L7.34148,4.00011728C7.02375,4.00011728,6.81088,4.00011728,6.606809999999999,4.0493473C6.51447,4.0712965,6.4241600000000005,4.100731,6.3368,4.137346C6.1434,4.218576,5.9776299999999996,4.343497,5.73525,4.526879L5.7057400000000005,4.549032L5.69192,4.558878C5.25426,4.889335,4.907019,5.15149,4.652082,5.38594C4.391493,5.62594,4.199349,5.86101,4.098253,6.1490100000000005C4.0155493,6.38408,3.9845204,6.633649999999999,4.00720399,6.88131C4.0348326,7.182840000000001,4.165441,7.4536,4.361981,7.74037C4.553498,8.02098,4.827901,8.3459,5.17263,8.75451L6.87179,10.76494C7.17948,11.12986,7.43316,11.429549999999999,7.666119999999999,11.63508C7.91038,11.85046,8.17222,12,8.5,12C8.82778,12,9.08962,11.85108,9.33388,11.63508C9.566220000000001,11.429549999999999,9.8199,11.12986,10.12821,10.76494L11.826740000000001,8.75451C12.1721,8.3459,12.4465,8.02098,12.63802,7.74037C12.83456,7.453609999999999,12.96454,7.183450000000001,12.9928,6.8806899999999995C13.01548,6.63303,12.98445,6.38347,12.90175,6.14839C12.80128,5.86101,12.60851,5.62532,12.34792,5.38594C12.09298,5.15149,11.74511,4.889335,11.30808,4.558879L11.294260000000001,4.549033L11.26475,4.526879C11.021740000000001,4.343497,10.8566,4.218576,10.6632,4.137346C10.575859999999999,4.100526,10.48554,4.0708863,10.39319,4.0487322C10.189119999999999,3.999502148,9.97625,3.999502148,9.65852,4.000117561L7.379160000000001,4.00011728ZM7.66298,6.2567L9.337019999999999,6.2567C9.51042,6.2567,9.65099,6.3944600000000005,9.65099,6.5643899999999995C9.65099,6.73432,9.51042,6.87207,9.337019999999999,6.87208L7.66298,6.87208C7.48958,6.87207,7.349019999999999,6.73432,7.349019999999999,6.5643899999999995C7.349019999999999,6.3944600000000005,7.48958,6.2567,7.66298,6.2567Z"
                              fill="#FFFFFF"
                              fill-opacity="1"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div className="licenseType">{prd}</div>
                    <div className="licenseVersion">{type}</div>
                    <div className="licenseLine"></div>
                    <div className="licenseDate">
                      授权至
                      {moment(_.license_deadline_time).format("YYYY/MM/DD")}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {products?.length > 0 && (
            <div className="productTitle">我们的产品</div>
          )}
          {products?.length > 0 && (
            <div
              className={
                showPlan
                  ? "productListBox showPlanProductListBox"
                  : "productListBox"
              }
            >
              {products?.map?.((item) => (
                <div className="productCard">
                  <img key={item.icon} src={item.icon} alt="" />
                  <div
                    className="productCardBtn"
                    onClick={() => {
                      window.open(item.product_home, "_block");
                    }}
                  >
                    查看详情
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Drawer
        destroyOnClose={true}
        closable={false}
        width={500}
        className="addEnterpriseDrawer"
        placement="right"
        title="新建企业或组织"
        extra={
          <CloseOutlined
            style={{ fontSize: "16px" }}
            onClick={() => {
              setOpen(false);
            }}
          />
        }
        onClose={() => {
          setOpen(false);
        }}
        visible={open}
        footer={
          <div className="drawerFooterBtnBox">
            <MyButton
              type="default"
              className=" ant-spaces"
              ml="10px"
              onClick={() => bindEnterprise()}
            >
              新建
            </MyButton>
            <MyButton className=" ant-spaces" onClick={() => setOpen(false)}>
              取消
            </MyButton>
          </div>
        }
      >
        <div className="labelDIv">
          <span>*</span>组织或企业名称
        </div>
        <Input
          value={enterpriseName}
          placeholder="输入组织或企业名称"
          onChange={(e) => {
            setEnterpriseName(e.target.value);
          }}
        ></Input>
      </Drawer>
    </div>
  );
}
