import request from '../../utils/request'
import { GETCONFIGMANAGELIST } from '../constants'

/**
 *  获取配置项列表
 * @param {*} params
 * @returns
 */
export const getConfigManageList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/cfg/', { params })
    dispatch({
      type: GETCONFIGMANAGELIST,
      payload: res.data.data,
    })
  }
}

/**
 * 新增配置
 * @param {*} values
 * @returns
 */
export const addConfigManage = (values) => {
  return async (dispatch) => {
    await request.post('sys/cfg/', values)
  }
}

/**
 * 修改配置
 * @param {*} values
 * @returns
 */
export const editConfigManage = (values) => {
  return async (dispatch) => {
    const res = await request.put(`sys/cfg/${values.id}/`, values)
    console.log(res)
  }
}

export const delConfigs = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/cfg/${id}/`)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/cfg/check/', { params, isLoading })
  }
}
