import React from 'react'
import ReactDOM from 'react-dom'
import request from 'axios'
// 通用的样式
import 'antd/dist/antd.css'
import 'element-theme-default'

import './index.scss'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import { setDeploy, setLogoUrl, setOaOrg, setTitleName } from './utils/cookie'
const origin = window.location.origin
console.log(origin, process.env)
const url = process.env['REACT_APP_BASE_URL']
  ? `${process.env['REACT_APP_BASE_URL']}/api/sys/config/`
  : `${origin}/api/sys/config/`
request.get(url).then((res) => {
  console.log(res.data.data)
  document.title = res.data.data?.web_title || '吾道科技'
  setLogoUrl(res.data.data?.logo_url || '')
  setTitleName(res.data.data?.title_name || '')
  setOaOrg(res.data.data?.oa_org || false)
  setDeploy(res?.data?.data?.deploy || '')
  localStorage.setItem('prefix', res.data?.data?.prefix || '')
  localStorage.setItem('sub_url', res?.data?.data?.sub_url || '')
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
})
