import { message } from "antd";
import request from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { login } from "../../store/actions/login";
import { LOGIN } from "../../store/constants";
import {
  setCompany,
  setSuperGroup,
  setToken,
  getToken,
  getRedirectUrl,
  getDeploy,
} from "../../utils/cookie";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (getToken()) {
      // if (getRedirectUrl()) {
      //   window.location.href = getRedirectUrl();
      // } else {
      history.push("/home");
      // }
      localStorage.setItem("firstLogin", true);
      return;
    }
    const redirectUrl = getRedirectUrl();
    let useSaas = false;
    if (redirectUrl?.includes("/saas")) {
      useSaas = true;
    }
    console.log("使用saas登录", useSaas);
    dispatch(login(getDeploy() === 'BOX' ? undefined : useSaas)).then((res) => {
      console.log(res);
      if (res) {
        console.log("home");
        message.success("登录成功");
        const to = localStorage.getItem('to');
        localStorage.removeItem('to')
        if(to){
          localStorage.setItem("firstLogin", true);
          history.push(to);
          return
        }
        history.push('/home')
        // if (getRedirectUrl()) {
        //   window.location.href = getRedirectUrl();
        // } else {
        // }
        localStorage.setItem("firstLogin", true);
      }
    });
  }, []);
  return <div></div>;
}
