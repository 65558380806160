import { GETUSERINFO, GETUSERLIST } from '../constants'

const init = {
  total: 0,
  userList: [],
  userLists: [],
  userInfo: {},
}
export default function getUserList(state = init, action) {
  if (action.type === GETUSERLIST) {
    return {
      ...state,
      total: action.payload.total,
      userList: action.payload.data,
      userLists: action.payload,
    }
  }
  if (action.type === GETUSERINFO) {
    return {
      ...state,
      userInfo: action.payload,
    }
  }
  return state
}
