// 封装axios
import axios from 'axios'
import {
  getToken,
  removeCompany,
  removeOaOrg,
  removeSuperGroup,
  removeToken,
  removeUserStatus,
  setRedirectUrl,
} from './cookie'
import store from '../store'
import { logout } from '../store/actions/login'
import history from './history'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { message, Spin } from 'antd'
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL || window.location.origin}/api/`,
  timeout: 50000,
  withCredentials: true,
})
message.config({
  maxCount: 1,
})

// 当前正在请求的数量
let requestCount = 0

// 显示loading
function showLoading() {
  if (requestCount === 0) {
    var dom = document.createElement('div')
    dom.setAttribute('id', 'loading')
    const contentDiv = document.getElementById('right_content')
    contentDiv ? contentDiv.appendChild(dom) : document.body.appendChild(dom)
    ReactDOM.render(<Spin tip="加载中..." size="large" />, dom)
  }
  requestCount++
}

// 隐藏loading
function hideLoading() {
  requestCount--
  if (requestCount === 0) {
    const contentDiv = document.getElementById('right_content');
    if(document.getElementById('loading')){
      contentDiv? contentDiv.removeChild(document.getElementById('loading'))
      : document.body?.removeChild(document.getElementById('loading'))
    }
  }
}

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // requestCount为0，才创建loading, 避免重复创建
    if (config.isLoading !== false) {
      showLoading()
    }
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    console.log(error)
    // 判断当前请求是否设置了不显示Loading
    if (error.config.isLoading !== false) {
      hideLoading()
    }

    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 判断当前请求是否设置了不显示Loading
    if (response.config.isLoading !== false) {
      hideLoading()
    }
    if (response.data.code == 3) {
      removeCompany()
      // store.dispatch(logout())
      removeToken()
      removeUserStatus()
      removeSuperGroup()
      if (window.location.pathname !== '/loginHtml') {
        setRedirectUrl(window.location.href);
      }
      window.location.href = window.location.origin + '/loginHtml'
    }
    if (response.data.code && response.data.code !== 0) {
      message.error(response.data.msg)
    }
    if(response.config.returnData){
      return response.data
    }
    // 对响应数据做点什么
    return response
  },
  function (error) {
    console.log(error)
    if (error.config.isLoading !== false) {
      hideLoading()
    }
    if (error.response.status === 500) {
      // 对响应错误做点什么
      // 网络问题
      message.error('网络繁忙，稍后重试', 3)
      // return Promise.reject(error)
    }
    if (error.response.status === 401) {
      removeToken()
      removeUserStatus()
      removeSuperGroup()
      removeCompany()
      removeOaOrg()
      if (window.location.pathname !== '/loginHtml') {
        setRedirectUrl(window.location.href);
      }
      window.location.href = window.location.origin + '/loginHtml'
      return Promise.reject('登录信息过期，请重新登录～')
      // store.dispatch(logout())
    }

    return Promise.reject(error)
  }
)
Component.prototype.$axios = instance
export default instance
