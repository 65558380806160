import { useEffect, useState } from "react";
import "./index.scss";
import { Button, Result, Spin, message } from "antd";
import Register from "./register";
import Api from "../../api/index";
import { setToken, getToken, removeToken } from "../../utils/cookie";
import { useGetState, useUnmount } from "ahooks";

export default function WXHtml() {
  const [userInfo, setUserInfo] = useState(null);
  const [isRegister, setIsRegister] = useState(false);

  const clearAll = () => {
    removeToken();
    window.sessionStorage.clear()
  };

  useUnmount(() => {
    clearAll();
  });

  //获取用户信息
  const getUserInfo = () => {
    Api.getUserInfo().then((res2) => {
      console.log(
        "🚀 ~ file: index.jsx:22 ~ Api.getUserInfo ~ res2:",
        res2.data.data
      );
      setUserInfo(res2.data.data);
    });
  };

  //禁止页面缩放
  const prohibitScalingFunc = () => {
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );
    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });
  };

  //监听界面关闭
  const listenerWindowClose = () => {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    if (isAndroid) {
      //安卓
      if (window.addEventListener) {
        document.addEventListener("visibilitychange", function () {
          //部分手机可以获取到
          // do something
          if (document.hidden) {
            clearAll();
          }
        });
      } else if (window.attachEvent) {
        // 主要是为了兼容老的IE
        window.attachEvent("onpagehide", function () {
          clearAll();
        });
      } else {
        window.onbeforeunload = function () {
          clearAll();
        };
      }
    } else {
      //苹果
      window.addEventListener(
        "pagehide",
        function (e) {
          clearAll();
        },
        false
      );
    }
  };

  useEffect(() => {
    document.title = "iWudao";
    //禁止界面缩放
    prohibitScalingFunc();
    //监听界面关闭
    listenerWindowClose();
    //获取用户信息
    const isRegisterStr = window.sessionStorage.getItem("isRegister")
    if (isRegisterStr && JSON.parse(isRegisterStr)) {
      getUserInfo();
    }
    setIsRegister(isRegisterStr && JSON.parse(isRegisterStr));
    if (window.sessionStorage.getItem("token")) {
      setToken(window.sessionStorage.getItem("token"));
    }
  }, []);

  //如果是微信环境。退出就关闭窗口
  const closeWin = () => {
    clearAll();
    if (document.addEventListener) {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          // eslint-disable-next-line no-undef
          WelkinJSBridge.call("closeWindow");
          // eslint-disable-next-line no-undef, no-restricted-globals
          parent.WeixinJSBridge.call("closeWindow");
        },
        false
      );
    } else if (document.attachEvent) {
      document.attachEvent("onWeixinJSBridgeReady", function () {
        //这个可以关闭ios系统的手机
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call("closeWindow");
      });
    }
    // 必须加最后这一句，否则关闭不了
    // eslint-disable-next-line no-undef
    WeixinJSBridge.call("closeWindow");
  };

  //登录
  const loginFunc = () => {
    //TODO: 调登录的接口
    Api.loginFunc({
      unionid: window.sessionStorage.getItem("unionid"),
      unique_key: window.sessionStorage.getItem("loginId"),
    }).then((res) => {
      if (res.data.code === 0) {
        closeWin();
      } else {
        message.error(res.data.msg);
      }
    });
  };

  if (!window.sessionStorage.getItem("token")) {
    return (
      <div className="wxHtmlContent">
        <Result title="请重新扫描二维码!" />
      </div>
    );
  }
  return (
    <div className="wxHtmlContent">
      {!isRegister ? (
        <div className="loginContentBox">
          <Result status="success" title="吾道科技登录确认" />
          <Button type="primary" className="loginBtn" onClick={loginFunc}>
            登录
          </Button>
          <Button className="closeBtn" onClick={closeWin}>
            取消
          </Button>
        </div>
      ) : (
        <Register
          closeWin={closeWin}
          userInfo={userInfo}
          loginId={window.sessionStorage.getItem("loginId")}
        ></Register>
      )}
      {/* <Button type="primary" onClick={closeWin}>
          关闭
        </Button> */}
    </div>
  );
}
