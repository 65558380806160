import {
  GETCASORGSLIST,
  GETCASSUBSETORGSLIST,
  GETORGALLLIST,
  GETORGLIST,
  GETORGTREELIST,
} from '../constants'

const init = {
  orgTreeList: [],
  orgTreeListCope: [],
  orgList: [],
  orgAllList: [],
  total: 0,
  orgInfos: {},
  casOrgsList: [],
  casOrgsTotal: 0,
  casSubsetOrgsList: [],
}
export default function getOrgTreeList(state = init, action) {
  if (action.type === GETORGTREELIST) {
    return {
      ...state,
      orgTreeList: action.payload,
      orgTreeListCope: action.payload[0]?.children || [],
    }
  }
  if (action.type === GETORGLIST) {
    return {
      ...state,
      orgList: action.payload.data,
      total: action.payload.total,
    }
  }
  if (action.type === GETORGALLLIST) {
    return {
      ...state,
      orgAllList: action.payload,
    }
  }
  if (action.type === GETCASORGSLIST) {
    const init = (arr) => {
      arr = arr.map((item) => {
        if (item.children) {
          item.children = init(item.children)
        }
        item.key = item.org_code
        item.children = []
        return item
      })

      return arr
    }
    let arr = init(action.payload.data)
    return {
      ...state,
      casOrgsList: arr,
      casOrgsTotal: Number(action.payload.total),
    }
  }
  if (action.type === GETCASSUBSETORGSLIST) {
    return {
      ...state,
      casSubsetOrgsList: action.payload,
    }
  }
  // if(action.type)
  return state
}
