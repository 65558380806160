import request from '../../utils/request'
import {
  GETAPPLPERMISSIONLIST,
  GETAUTOAPPROVESTATUS,
  GETPERMISSIONFUCLIST,
  GETPERMISSIONFUNCTREELIST,
  GETPERMISSIONLIST,
  GETPERMISSIONLISTS,
  GETPERMISSIONMENULIST,
  GETPERMISSIONMENUTREELIST,
  GETPERMISSIONORGLIST,
  GETPERMISSIONORGLISTS,
  GETPERMISSIONUSERLIST,
  GETPERMISSIONUSERLISTS,
} from '../constants'

/**
 * 获取角色列表
 * @param {*} params
 * @returns
 */
export const getPermissionList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/', {
      params,
    })
    // console.log(res.data.data)
    const init = (arr) => {
      arr = arr.map((item) => {
        item.title = item.role_name
        item.description = item.role_name
        item.option = item.role_name
        item.key = item.role_id
        item.label = item.role_name
        item.value = item.role_id
        return item
      })
      return arr
    }
    let arr = init(res.data.data.data)
    dispatch({
      type: GETPERMISSIONLIST,
      payload: {
        arr,
        total: res.data.data.total,
      },
    })
  }
}

export const getPermissionLists = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/', {
      params,
      isLoading,
    })
    const init = (arr) => {
      arr = arr.map((item) => {
        item.title = item.role_name
        item.description = item.role_name
        item.option = item.role_name
        item.key = item.role_id
        return item
      })
      return arr
    }
    let arr = init(res.data.data)
    // console.log(res.data.data)
    dispatch({
      type: GETPERMISSIONLISTS,
      payload: arr,
    })
  }
}

export const addPermission = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/permission/role/', values)
    console.log(res)
    return res
  }
}

export const editPermission = (values) => {
  return async (dispatch) => {
    const res = await request.put(`sys/permission/role/${values.id}/`, values)
    console.log(res)
    return res
  }
}

export const delPermission = (id) => {
  return async (dispatch) => {
    await request.delete(`sys/permission/role/${id}/`)
  }
}

export const batchDelPermission = (params) => {
  return async (dispatch) => {
    await request.delete('sys/permission/role/', {
      params,
    })
  }
}

export const getPermissionUserList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/user/', {
      params,
      isLoading,
    })
    dispatch({
      type: GETPERMISSIONUSERLIST,
      payload: res.data.data,
    })
  }
}

export const getPermissionUserLists = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/user/', {
      params,
    })
    console.log(res)
    dispatch({
      type: GETPERMISSIONUSERLISTS,
      payload: res.data.data,
    })
  }
}

export const getPermissionOrgList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/org/', {
      params,
      isLoading,
    })
    // console.log(res)
    dispatch({
      type: GETPERMISSIONORGLIST,
      payload: res.data.data,
    })
  }
}
export const getPermissionOrgLists = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/org/', {
      params,
      isLoading,
    })
    // console.log(res)

    dispatch({
      type: GETPERMISSIONORGLISTS,
      payload: res.data.data,
    })
  }
}

export const getPermissionMenuTreeList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/menu/tree/', {
      params,
      isLoading,
    })
    // console.log(res)
    dispatch({
      type: GETPERMISSIONMENUTREELIST,
      payload: res.data.data,
    })
  }
}
export const getPermissionFuncTreeList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/func/tree/', {
      params,
      isLoading,
    })
    // console.log(res)
    dispatch({
      type: GETPERMISSIONFUNCTREELIST,
      payload: res.data.data,
    })
  }
}

export const batchAddUser = (values, isLoading) => {
  return async (dispatch) => {
    const res = await request.post('sys/permission/role/user/', values, {
      isLoading,
    })
    console.log(res)
  }
}

export const batchDelUser = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.delete('sys/permission/role/user/', {
      params,
      isLoading,
    })
    console.log(res)
  }
}

export const batchAddOrg = (values, isLoading) => {
  return async (dispatch) => {
    const res = await request.post('sys/permission/role/org/', values, {
      isLoading,
    })
    console.log(res)
  }
}

export const batchDelOrg = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.delete('sys/permission/role/org/', {
      params,
      isLoading,
    })
    console.log(res)
  }
}

export const getMenuPower = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/menu/', {
      params,
      isLoading,
    })
    dispatch({
      type: GETPERMISSIONMENULIST,
      payload: res.data.data,
    })
  }
}

export const addMenuPower = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/permission/role/menu/', values)
    console.log(res)
  }
}

export const getFucPower = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/permission/role/func/', {
      params,
      isLoading,
    })
    dispatch({
      type: GETPERMISSIONFUCLIST,
      payload: res.data.data,
    })
  }
}

export const addFucPower = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/permission/role/func/', values)
    console.log(res)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/permission/role/check/', {
      params,
      isLoading,
    })
  }
}
export const removeMembers = (params, isLoading) => {
  console.log(params)
  return async (dispatch) => {
    const res = await request.delete('sys/org/user/remove/', {
      data: params,
    })
    return res
  }
}

export const handover = (values) => {
  return async (dispatch) => {
    const res = await request.put('sys/user/handover/', values)
    console.log(res)
    return res
  }
}

export const invite = (values, isLoading) => {
  return async (dispatch) => {
    const res = await request.post('sys/comp/invite/', values, { isLoading })
    return res
  }
}

export const getApplPermissionList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/user/application/', { params })
    dispatch({
      type: GETAPPLPERMISSIONLIST,
      payload: res.data.data,
    })
  }
}
export const batchApproveApplPermission = (values) => {
  return async (dispatch) => {
    const res = await request.patch('sys/comp/user/application/', values)
    return res
  }
}

export const getAutoApproveStatus = (isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/auto-approve/', { isLoading })
    dispatch({
      type: GETAUTOAPPROVESTATUS,
      payload: res.data.data,
    })
    return res
  }
}

export const editAutoApproveStatus = (values, isLoading) => {
  return async (dispatch) => {
    const res = await request.put('sys/comp/auto-approve/', values, {
      isLoading,
    })
    return res
  }
}

export const joinComp = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/comp/join/', values)
    return res
  }
}
