import { GETFUCLIST, GETFUCTREELIST } from '../constants'

const init = {
  fucTreeList: [],
  fucList: [],
  fucAllList: [],
  total: 0,
}

export default function getFucTreeList(state = init, action) {
  if (action.type === GETFUCTREELIST) {
    return {
      ...state,
      fucTreeList: action.payload,
    }
  }
  if (action.type === GETFUCLIST) {
    return {
      ...state,
      fucList: action.payload.data,
      total: action.payload.total,
      fucAllList: action.payload,
    }
  }
  return state
}
