import request from '../../utils/request'
import qs from 'qs'
import { GETUSERINFO, GETUSERLIST } from '../constants'

/**
 * 获取用户列表
 * @param {*} params
 * @returns
 */
export const getUserList = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/user/', {
      params,
      isLoading,
    })
    console.log(res.data.data)
    dispatch({
      type: GETUSERLIST,
      payload: res.data.data,
    })
  }
}

/**
 * 获取用户信息
 * @param {*} id
 * @returns
 */
export const getUserInfo = (id) => {
  return async (dispatch) => {
    const res = await request.get(`sys/comp/user/${id}/`)
    console.log(res)
    dispatch({
      type: GETUSERINFO,
      payload: res.data.data,
    })
  }
}

/**
 * 新增用户
 * @param {*} values
 * @returns
 */
export const addUser = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/comp/user/', values)
    console.log(res)
    return res
  }
}

/**
 * 修改用户
 * @param {*} values
 * @returns
 */
export const editUser = (values) => {
  return async (dispatch) => {
    const res = await request.put(`sys/comp/user/${values.emp_id}/`, values)
    console.log(res)
    return res
  }
}

/**
 * 删除用户
 * @param {*} id
 * @returns
 */
export const delUsers = (id) => {
  return async (dispatch) => {
    const res = await request.delete(`sys/comp/user/${id}/`)
    console.log(res)
    return res
  }
}

/**
 * 批量删除
 * @param {*} values
 * @returns
 */
export const batchDel = (params) => {
  return async (dispatch) => {
    await request.delete('sys/comp/user/', {
      params,
    })
  }
}

/**
 * 重置密码
 * @param {*} values
 * @returns
 */
export const resetPassword = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/password/reset/', values)
    console.log(res)
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/user/check/', {
      params,
      isLoading,
    })
  }
}

export const switchCoList = () => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/switch/')
    return res
  }
}

export const editPersonalInfo = (params) => {
  return async (dispatch) => {
    const res = await request({
      method: 'patch',
      url: `sys/user/info/`,
      data: params,
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
    })
    console.log(res)
    return res
  }
}

export const logoutComp = (params) => {
  return async (dispatch) => {
    const res = await request.delete('sys/comp/logout/', { data: params })
    console.log(res)
    return res
  }
}

export const editMobile = (values) => {
  return async (dispatch) => {
    const res = await request.put('sys/user/mobile/', values)
    return res
  }
}
export const editEmail = (values) => {
  return async (dispatch) => {
    const res = await request.put('sys/user/email/', values)
    return res
  }
}

export const getWxQcd = (params, isLoading) => {
  console.log(isLoading)
  return async (dispatch) => {
    const res = await request.get('sys/user/wechat/', { params, isLoading })
    return res
  }
}

export const editWechatInfo = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/wechat/info/', { params, isLoading })
    return res
  }
}

export const getBindStatus = (values, isLoading) => {
  return async (dispatch) => {
    const res = await request.put('sys/polling/', values, { isLoading })
    return res
  }
}
