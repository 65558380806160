import React, { useRef } from 'react'
import {
  Card,
  Form,
  Button,
  Modal,
  Space,
  Table,
  Drawer,
  ConfigProvider,
  Breadcrumb,
  Input,
  message,
  Tooltip,
} from 'antd'
import styles from './index.module.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addComps,
  batchDel,
  delComps,
  editComps,
  getTrigger,
} from '../../../../store/actions/comp'
import { useState } from 'react'
import zhCH from 'antd/lib/locale/zh_CN'
import { useSize, useGetState } from 'ahooks'
import MyButton from '../../../../components/MyButton'
import {
  getCompTemp,
  getProTrigger,
  syncTemp,
} from '../../../../store/actions/productCfg'
import { MyIcon } from '../../../../routers'
export default function ProductConfgTemp() {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [delTemp, setDelTemp] = useState(false)
  const [delStatus, setDelStatus] = useState(false)
  const [coList, setCoList, getCoList] = useGetState({})
  const [batchCompDel, setBatchCompDel] = useState(false)
  const [syncTempModal, setSyncTempModal] = useState(false)
  const [tempItemInfo, setTempItemInfo] = useState({})
  const [selectedRowKey, setSelectedRowKey] = useState(null)
  const tableRef = useRef(null)
  const size = useSize(tableRef)

  const rulesObj = useRef({
    co_code: [
      {
        required: true,
        message: '产品配置编号不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          console.log(value)
          return dispatch(
            getTrigger(
              {
                pk: getCoList().co_id ? getCoList().co_id : null,
                field: 'code',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('产品配置模板编号已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    co_name: [
      {
        required: true,
        message: '产品配置名称不能为空',
      },
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
      {
        validator(_, value) {
          return dispatch(
            getTrigger(
              {
                pk: getCoList().co_id ? getCoList().co_id : null,
                field: 'name',
                value,
              },
              false
            )
          ).then(({ data }) => {
            if (!data.data) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('产品配置模板名称已存在'))
          })
        },
        validateTrigger: 'onBlur',
      },
    ],
    co_desc: [
      {
        pattern: /^[^\s]+(\s+[^\s]+)*/,
        message: '不能以空格开头',
      },
    ],
  })

  //获取产品模板
  useEffect(() => {
    dispatch(
      getCompTemp({
        co_type_code: 'PROD_TEMPLATE',
      })
    )
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue(coList)
    }, 0)
  }, [coList])
  const { total, productCfgTempList } = useSelector((state) => state.productCfg)
  // console.log(total, productCfgTempList)

  const paramsRef = useRef({
    page: 1,
    page_size: 10,
  })
  useEffect(() => {
    paramsRef.current.page = 1
    paramsRef.current.page_size = 10
  }, [])

  const showSyncModal = (co) => {
    setTempItemInfo(co)
    setSyncTempModal(true)
  }

  const syncTempFn = async (info) => {
    setSyncTempModal(false)
    const res = await dispatch(syncTemp({ template_id: info.co_id }))
    if (res.data.code === 0) {
      message.success('同步成功')
    } else {
      message.error(res.data.msg)
    }
  }

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 70,
      ellipsis: {
        showTitle: false,
      },
      render(text, record, index) {
        return (
          <span>
            {(paramsRef.current.page - 1) * paramsRef.current.page_size +
              index +
              1}
          </span>
        )
      },
    },
    {
      title: '产品配置模板编号',
      dataIndex: 'co_code',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '产品配置模板名称',
      dataIndex: 'co_name',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: '产品配置模板描述',
      dataIndex: 'co_desc',
      width: 220,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip
          overlayStyle={{ maxWidth: 560 }}
          placement="leftTop"
          title={text}
        >
          {text}
        </Tooltip>
      ),
    },
    {
      title: '是否预置',
      dataIndex: 'is_preset',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render(is_preset) {
        return is_preset ? (
          <div className="is-true">是</div>
        ) : (
          <div className="is-false">否</div>
        )
      },
    },
    {
      title: '创建时间',
      dataIndex: 'created_time',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '修改时间',
      dataIndex: 'updated_time',
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="leftTop" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: '操作',
      key: 'co',
      width: 100,
      fixed: 'right',
      render(co) {
        return (
          <Space>
            {!co.is_preset && (
              <Button type="link" className="edit" onClick={() => editComp(co)}>
                修改
              </Button>
            )}

            <Button
              type="link"
              className="edit"
              onClick={() => {
                showSyncModal(co)
              }}
            >
              强制同步
            </Button>
            {!co.is_preset && (
              <Button
                type="link"
                className="del"
                onClick={() => {
                  delTemps(co)
                }}
              >
                删除
              </Button>
            )}
          </Space>
        )
      },
    },
  ]

  // 新增配置模板弹框
  const addComp = () => {
    console.log('新增产品配置模板')
    setVisible(true)
    setCoList({})
  }
  const formRef = useRef(null)
  //修改产品配置模板
  const editComp = (co) => {
    console.log('修改产品配置模板', co)
    co = JSON.parse(JSON.stringify(co))
    setCoList(co)
    setVisible(true)
  }

  // 删除产品配置模板弹框并获取产品配置模板信息并保存
  const delTemps = async (co) => {
    setCoList(co)

    console.log(co)
    let params = {}
    params.co_id = co.co_id
    const res = await dispatch(getProTrigger(params, false))
    if (res.data.data) {
      setDelStatus(false)
      setDelTemp(true)
    } else {
      setDelTemp(true)
      setDelStatus(true)
    }
  }
  const [batchId, setBatchId] = useState('')
  const batchDels = async (id) => {
    await dispatch(batchDel(id))
    setBatchCompDel(false)
    setBatchId('')
    message.success('批量删除成功')
    paramsRef.current.page = 1
    await dispatch(
      getCompTemp({
        co_type_code: 'PROD_TEMPLATE',
      })
    )
  }
  // 批量删除
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
      setBatchId(`${selectedRowKeys}`)
    },
  }
  // 新增产品配置模板
  const onFinish = async (values) => {
    console.log(values)
    if (coList.co_id) {
      await dispatch(editComps({ ...values, id: coList.co_id }))
    } else {
      await dispatch(addComps({ ...values, co_type_code: 'PROD_TEMPLATE' }))
    }
    message.success(
      coList.co_id ? '修改产品配置模板成功' : '新增产品配置模板成功'
    )
    setVisible(false)
    paramsRef.current.page = 1
    await dispatch(
      getCompTemp({
        ...paramsRef.current,
        co_type_code: 'PROD_TEMPLATE',
      })
    )
  }
  // 删除产品配置模板
  const delCompl = async (id) => {
    console.log(id, coList)
    setDelTemp(false)
    const res = await dispatch(delComps(id))
    if (res.data.code === 0) {
      message.success('删除产品配置模板成功')
      paramsRef.current.page = 1
      await dispatch(
        getCompTemp({
          ...paramsRef.current,
          co_type_code: 'PROD_TEMPLATE',
        })
      )
    } else {
      message.error(res.data.msg)
    }
  }
  // 搜索产品配置模板
  const searchComp = async (values) => {
    console.log(values)
    paramsRef.current.fuzzy_name = values.fuzzy_name
    paramsRef.current.page = 1
    await dispatch(
      getCompTemp({
        ...paramsRef.current,
        co_type_code: 'PROD_TEMPLATE',
      })
    )
  }
  // 分页处理
  const onChange = (page, pageSize) => {
    console.log(page, pageSize)
    paramsRef.current.page = page.current
    paramsRef.current.page_size = page.pageSize
    paramsRef.current.current = page.defaultCurrent
    dispatch(
      getCompTemp({ ...paramsRef.current, co_type_code: 'PROD_TEMPLATE' })
    )
  }

  const paginations = {
    defaultCurrent: 1,
    current: paramsRef.current.page,
    defaultPageSize: 10,
    total: total,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => {
      return `共${total}条`
    },
  }
  const [form] = Form.useForm()
  const onReset = () => {
    form.resetFields()
  }

  const getRowClassName = (record) => {
    return record.co_id === selectedRowKey ? 'selected-row' : ''
  }

  return (
    <div className={styles.root}>
      <div className="right_content">
        <Breadcrumb>
          <Breadcrumb.Item>系统管理</Breadcrumb.Item>
          <Breadcrumb.Item>产品配置模板</Breadcrumb.Item>
        </Breadcrumb>
        <Card className="search-card">
          <Form
            colon={false}
            form={form}
            autoComplete="off"
            className="form-search"
            onFinish={searchComp}
          >
            <div className="flex">
              <Form.Item label="产品配置模板" name="fuzzy_name">
                <Input
                  placeholder="请输入产品配置模板编号或名称"
                  style={{ width: 238 }}
                ></Input>
              </Form.Item>
            </div>
            <Form.Item>
              <Space size={[20]}>
                <MyButton type="default" htmlType="submit">
                  查询
                </MyButton>
                <MyButton onClick={onReset}>重置</MyButton>
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <Space size={[24]} className="space-heigth">
          <MyButton type="default" onClick={addComp}>
            新增
          </MyButton>
          <MyButton
            type="default"
            onClick={() => {
              if (!batchId) return message.error('请至少勾选一条数据')
              setBatchCompDel(true)
            }}
          >
            批量删除
          </MyButton>
        </Space>
        <div className="right_table" ref={tableRef}>
          <ConfigProvider locale={zhCH}>
            <Table
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    setSelectedRowKey(record?.co_id)
                  }, // 点击行
                }
              }}
              rowClassName={getRowClassName}
              scroll={{ y: size?.height - 95 + 'px' }}
              size="small"
              onChange={onChange}
              rowKey="co_id"
              rowSelection={{
                ...rowSelection,
                getCheckboxProps(value) {
                  return {
                    disabled: value.is_preset,
                  }
                },
              }}
              columns={columns}
              dataSource={productCfgTempList}
              showCount
              pagination={paginations}
            ></Table>
          </ConfigProvider>
        </div>
      </div>
      {/* 新增产品配置模板 */}
      <Drawer
        width={624}
        destroyOnClose={true}
        size="large"
        maskClosable={false}
        title={coList.co_id ? '修改产品配置模板' : '新增产品配置模板'}
        placement="right"
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
      >
        <Form
          colon={false}
          autoComplete="off"
          validateTrigger={['onBlur', 'onChange']}
          ref={formRef}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          initialValues={{
            is_default_tmpl: false,
          }}
          onFinish={onFinish}
        >
          <div className="form-btn">
            <div className="flex">
              <Form.Item
                className="w-50"
                label="产品配置模板编号"
                name="co_code"
                rules={rulesObj.current.co_code}
              >
                <Input
                  width={264}
                  maxLength={50}
                  placeholder="请输入产品配置编号，不超过50个字"
                ></Input>
              </Form.Item>
              <Form.Item
                className="w-50"
                label="产品配置模板名称"
                name="co_name"
                rules={rulesObj.current.co_name}
              >
                <Input
                  maxLength={50}
                  placeholder="请输入产品配置名称，不超过50个字"
                ></Input>
              </Form.Item>
            </div>

            <Form.Item
              label="产品配置模板描述"
              name="co_desc"
              rules={rulesObj.current.co_desc}
            >
              <Input.TextArea
                maxLength={500}
                showCount
                placeholder="请输入产品配置描述，不超过500个字"
                style={{ height: '100px' }}
              ></Input.TextArea>
            </Form.Item>
          </div>

          <Form.Item>
            <div className="flex_end">
              <Space>
                <MyButton onClick={() => setVisible(false)}>取消</MyButton>
                <MyButton type="default" htmlType="submit">
                  {coList.co_id ? '修改' : '新增'}
                </MyButton>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      {/* 删除产品配置对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setDelTemp(false)}
        title="删除产品配置模板"
        visible={delTemp}
        getContainer={false}
        footer={[
          delStatus ? (
            <Space size={[24]}>
              <MyButton type="default" onClick={() => setDelTemp(false)}>
                确认
              </MyButton>
            </Space>
          ) : (
            <Space size={[24]} className="model-btn">
              <MyButton onClick={() => setDelTemp(false)}>取消</MyButton>
              <MyButton type="default" onClick={() => delCompl(coList.co_id)}>
                删除
              </MyButton>
            </Space>
          ),
        ]}
      >
        {delStatus ? (
          <p>产品配置模板"{coList.co_name}"还有企业使用，无法删除！</p>
        ) : (
          <div>
            <p>
              <MyIcon
                style={{ fontSize: 40 }}
                type="icon-a-gaojingshixin1"
              ></MyIcon>
            </p>
            <p>
              删除产品配置模板"{coList.co_name}"后，企业将无法使用该模板配置!
            </p>
            <p>确定要删除吗？</p>
          </div>
        )}
      </Modal>

      {/* 批量删除产品配置模板对话框 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setBatchCompDel(false)}
        onOk={() => batchDels(batchId)}
        title="批量删除产品配置模板"
        visible={batchCompDel}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setBatchCompDel(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => batchDels(batchId)}>
              删除
            </MyButton>
          </Space>,
        ]}
      >
        <p>
          <MyIcon
            style={{ fontSize: 40 }}
            type="icon-a-gaojingshixin1"
          ></MyIcon>
        </p>
        <p>删除所选产品配置模板后将删除所选产品配置模板下所有配置</p>
        <p>确定要删除所选产品配置模板吗？</p>
      </Modal>

      {/* 强制同步模板二次确认弹窗 */}
      <Modal
        bodyStyle={{ textAlign: 'center' }}
        centered
        onCancel={() => setSyncTempModal(false)}
        title="强制同步产品配置模板"
        visible={syncTempModal}
        getContainer={false}
        footer={[
          <Space size={[24]} className="model-btn">
            <MyButton onClick={() => setSyncTempModal(false)}>取消</MyButton>
            <MyButton type="default" onClick={() => syncTempFn(tempItemInfo)}>
              确定
            </MyButton>
          </Space>,
        ]}
      >
        <p>强制同步产品配置模板后，所有关联企业将更新模板配置数据</p>
        <p>确定要同步吗？</p>
      </Modal>
    </div>
  )
}
