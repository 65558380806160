import React, { useEffect } from 'react'
import about1 from '../images/about1.png'
import logo from '../images/logo.png'
import '../styles/page.css'

export default function ServiceAgreement() {
  useEffect(() => {
    document.title = '服务协议'
  }, [])
  return (
    <div>
      {/* <!-- 头部 --> */}
      <div class="header">
        <img src={about1} alt="" class="bg" />
        <div class="header-con">
          {/* <!-- logo --> */}
          <img class="logo" src={logo} alt="果冻科技" />

          {/* <!-- 导航 --> */}
          <ul class="nav"></ul>
        </div>

        <h2>法律信息 / LEGAL INFORMATION</h2>
      </div>

      {/* <!-- 菜单 --> */}
      <div class="menu">
        <div class="menu-con">
          <span>
            您的位置：
            <a href="/home" class="on">
              首页
            </a>
            <span>/</span>
            <a href="javascript:;">果冻科技用户服务协议</a>
          </span>
        </div>
      </div>

      {/* <!-- 协议 --> */}
      <div class="law">
        <h1>果冻科技用户服务协议</h1>

        <h2>一、特别提示</h2>
        <p>
          在此特别提醒您（用户）在注册成为果冻科技用户之前，请认真阅读本《果冻用户服务协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。您同意并点击确认本协议条款且完成注册程序后，才能成为果冻网站的正式注册用户，并享受果冻网站的各类服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。若您不同意本协议，或对本协议中的条款存在任何疑问，请您立即停止果冻用户注册程序，并可以选择不使用本网站服务。
        </p>

        <p>
          本协议约定杭州与能信息技术有限公司（以下简称“果冻科技”）与用户之间关于使用果冻网站的权利义务。“用户”是指注册、登录、使用本服务的个人、单位。本协议可由果冻网站及果冻科技随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在果冻网站中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用果冻网站提供的服务，用户继续使用果冻网站提供的服务将被视为接受修改后的协议。
        </p>

        <p>
          如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人，并在您监护人的指导下阅读本协议和使用我们的服务。若您非中华人民共和国境内（为本协议之目的，不包括香港、澳门特别行政区及台湾地区）用户，您还需同时遵守您所属国家或地区的法律，且您确认，订立并履行本协议不违反您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规。
        </p>

        <h2>二、账户注册、使用和注销</h2>
        <p>
          我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。
        </p>

        <p>(一) 注册</p>

        <p>
          1.
          用户在使用本服务前需要注册一个“果冻”账户。“果冻”账户应当使用手机号码绑定注册。请用户使用尚未与“果冻”账户绑定的手机号码，以及未被果冻网站根据本协议封禁的手机号码注册“果冻”账户。果冻网站可以根据用户需求或产品需要对账户注册和绑定的方式进行变更，而无须事先通知用户。
        </p>
        <p>
          2.
          如果注册申请者有被果冻网站封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，果冻网站将拒绝其注册申请。
        </p>
        <p>
          3.
          鉴于“果冻”账户的绑定注册方式，您同意果冻网站在注册时将允许您的手机号码及手机设备识别码等信息用于注册。
        </p>
        <p>
          4.
          在用户注册及使用本服务时，果冻网站需要搜集能识别用户身份的个人信息以便可以在必要时联系用户，或为用户提供更好的使用体验。果冻网站搜集的信息包括但不限于用户的姓名、地址；果冻网站同意对这些信息的使用将受限于用户个人隐私信息保护的约束，详见《果冻科技用户隐私协议》。
        </p>
        <p>
          5.
          用户在账户中设置的昵称、头像、签名、留言等请务必遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，否则果冻网站可能会取消您的昵称、头像、签名。
        </p>

        <p>(二) 使用</p>

        <p>
          用户一旦注册成功，成为果冻网站的用户，将得到一个用户名和密码，并有权利使用自己的用户名及密码随时登陆果冻网站。而身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作、发出的指令视为您本人做出。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的风险和损失需要由您自行承担。
        </p>

        <p>您同意：</p>
        <p>
          1.
          基于不同的终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份。例如您的果冻账户在新设备首次登录的，我们可能通过密码加校验码的方式识别您的身份。
        </p>
        <p>
          2.
          为了保障您的账户安全，请把手机及其他设备的密码设置成与果冻账户的密码不一致。如您发现有他人冒用或盗用您的账户登录名及密码，或您的手机或其他有关设备丢失时，请您立即以有效方式通知我们，以保障您的合法权益，否则后果自负。您在持续登录果冻网站时段结束时，请以正确步骤退出网站。
        </p>
        <p>
          3.
          果冻账户仅限您本人使用，不得以任何形式转让、借用、赠与、继承，但果冻账户内的相关财产权益可被依法继承。
        </p>
        <p>
          4.
          基于运行和交易安全的需要，我们可能会暂停或者限制果冻网站服务部分功能，或增加新的功能。
        </p>
        <p>
          5.
          为了维护良好的网络环境，我们有时需要了解您使用果冻网站服务的真实背景及目的，如我们要求您提供相关信息或资料的，请您配合提供。
        </p>
        <p>
          6.
          用户对用户名和密码的安全负全部责任，同时对以其用户名进行的所有活动和事件负全责。
        </p>

        <p>(三) 注销</p>
        <p>
          在需要终止使用果冻网站服务时，符合以下条件的，您可以申请注销您的账户：
        </p>
        <p>1. 您仅能申请注销您本人的账户，并依照我们的流程进行注销。</p>
        <p>
          2.
          您可以通过自助或者人工的方式申请注销账户，但如果您使用了我们提供的安全产品，请在该安全产品环境下申请注销。
        </p>
        <p>
          3.
          您申请注销的账户处于正常状态，即您的账户的信息是最新、完整、正确的。
        </p>
        <p>
          4.
          为了维护您和其他用户的合法利益，您申请注销的账户，应当不存在未了结的权利义务或其他因为注销该账户会产生纠纷的情况。
        </p>
        <p>
          5.
          为了防止资源占用，如您连续18个月未使用您的账户或以我们认可的其他方式登录过您的账户，我们可能会对该账户进行注销，您将不能再通过该账户登录果冻网站。
        </p>
        <p>
          6.
          账户注销后，您将无法使用我们的服务，双方的权利义务终止（本协议另有约定不得终止的或依其性质不能终止的除外），同时还可能产生如下结果：
        </p>
        <p>（1）与账户关联的权益（如优惠券等）均将作废；</p>
        <p>
          （2）如您在注销账户前存在违约、侵权等不当行为或未完结合同的，您仍应承担相应责任；
        </p>
        <p>（3）一旦注销成功，账户记录、账户功能等将无法恢复或提供。</p>

        <h2>三、用户声明与保证</h2>
        <p>
          1.
          用户承诺其为具有完全民事行为能力的民事主体，且具有达成交易履行其义务的能力。
        </p>
        <p>
          2.
          用户有义务在注册时提供自己的真实资料，并保证诸如手机号码等内容的有效性及安全性，保证果冻工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。为方便用户更顺畅而便捷地使用果冻网站的服务，建议用户尽可能地补充完整的用户信息。
        </p>
        <p>
          3.
          用户使用果冻网站的过程中应当遵守网站规定，遵守中华人民共和国相关法律法规（本协议中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等，下同）及公序良俗、社会公德，包含但不限于如下原则：
        </p>
        <p>(1) 遵守所有与网络服务有关的网络协议、规定和程序；</p>
        <p>(2) 不得违反中华人民共和国宪法所确定的基本原则；</p>
        <p>(3) 不得危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</p>
        <p>(4) 不得损害国家荣誉和利益；</p>
        <p>
          (5)
          不得散布谣言，散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪，扰乱社会秩序，破坏社会稳定；
        </p>
        <p>
          (6)
          不得利用果冻网站网络服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料，侵害他人合法权益；
        </p>
        <p>
          (7)
          不得利用果冻网站网络服务系统进行任何可能对互联网的正常运转造成不利影响的行为；
        </p>
        <p>(8) 不得利用果冻网站网络服务系统进行任何不利于果冻网站的行为；</p>
        <p>
          (9)
          如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告果冻网站。
        </p>
        <p>(10) 不得有法律、行政法规禁止的其他内容等。</p>

        <h2>四、内容所有权</h2>
        <p>
          1.
          果冻网站提供的网络服务内容可能包括：文字、软件、声音、图片、录象、图表等。除非果冻另行声明，本网站的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图表、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密）及相关权利，均归果冻科技所有，受相关知识产权法律保护。来源于第三方的未经果冻科技任何编辑或处理的信息或数据的知识产权，由该第三方或其他人权利人享有。用户只有在获得果冻科技或其他权利人的授权后才能使用这些内容，而不能擅自复制、再造这些内容，或创造与这些内容相关的派生产品。
        </p>
        <p>
          2.
          果冻网站仅为用户提供正常的访问服务，禁止用户实施任何以商业为目的的数据爬取行为。未经果冻书面授权许可，任何人不得对果冻平台所提供的信息或数据进行超过必要使用限度的采集或使用计算机程序进行采集，不得擅自批量获取（包括但不限于抓取、复制、传播、展示、下载、录像）或使用果冻平台的数据、信息和资料，不得以任何方式（包括但不限于恶意干预数据）影响果冻网站的正常运营和服务。
        </p>

        <h2>五、服务的终止</h2>
        <p>1. 在下列情况下，果冻网站有权终止向用户提供服务：</p>
        <p>
          1)
          在用户违反本服务协议相关规定时，果冻网站有权终止向该用户提供服务；如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，果冻网站有权直接单方面终止向该用户提供服务；
        </p>
        <p>
          2)
          如果冻网站通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，果冻网站以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，果冻网站有权终止向该用户提供服务；
        </p>
        <p>3) 本服务条款终止或更新时，用户明示不愿接受新的服务条款；</p>
        <p>4) 其它果冻网站认为需终止服务的情况。</p>
        <p>
          2.
          服务终止后，果冻网站没有义务为用户保留原账户中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
        </p>
        <p>
          3.
          用户理解并同意，即便在本协议终止及用户的服务被终止后，果冻网站仍有权：
        </p>
        <p>1) 继续保存您的用户信息；</p>
        <p>
          2)
          继续向用户主张在其使用果冻网站服务期间因违反法律法规、本协议及平台其他规则而应承担的责任。
        </p>

        <h2>六、服务的变更、中断</h2>
        <p>
          1.
          鉴于网络服务的特殊性，果冻网站保留在不事先通知用户的情况下随时变更、中断或终止部分或全部网络服务的权利，并删除（不再保存）用户在使用过程中提交的任何资料，对于所有服务的变更、中断或终止而造成的任何损失，果冻网站无需对任何用户或任何第三方承担任何责任。
        </p>
        <p>
          2.
          果冻网站需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，果冻网站将尽可能事先进行通告，但果冻网站及果冻科技无需为此承担任何责任。
        </p>

        <h2>七、服务条款修改</h2>
        <p>
          1.
          果冻网站及果冻科技有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，果冻网站及果冻科技将会通过适当方式向用户提示修改内容。
        </p>
        <p>
          2.
          如果不同意果冻网站及果冻科技对本服务条款所做的修改，用户有权停止使用果冻网站的服务。
        </p>
        <p>
          3.
          如果用户继续使用果冻网站的服务，则视为用户接受果冻网站及果冻科技对本服务条款所做的修改。
        </p>

        <h2>八、免责与赔偿声明</h2>
        <p>
          1.
          若果冻网站或果冻科技已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
        </p>

        <p>
          2.
          果冻网站是按照现有技术和条件所能达到的现状提供的。果冻将尽最大努力向用户提供令人满意的服务，确保服务的连贯性和安全性，但果冻不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。用户明确同意其使用果冻网站所存在的风险将完全由其自己承担，因其使用果冻网站而产生的一切后果也由其自己承担。
        </p>

        <p>
          3.
          用户明确同意其使用果冻网站所存在的风险将完全由其自己承担，因其使用果冻网站而产生的一切后果也由其自己承担，果冻网站对用户不承担任何责任。
        </p>

        <p>
          4.
          用户同意保障和维护果冻网站、果冻科技及其他用户的利益，由于用户在使用果冻网站有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给果冻网站、果冻科技及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。
        </p>

        <p>
          5.
          用户同意若用户发布信息时不能履行和遵守协议中的规定，为维护果冻的形象、荣誉、安全，果冻将有权删除用户发布的相关信息，直到封闭用户的账户或/和暂时、永久禁止在果冻发布信息的处理，同时保留依法追究用户的法律责任的权利，果冻系统中的记录有可能作为用户违反法律和本协议的证据。
        </p>

        <p>
          6.
          果冻网站不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由果冻网站实际控制的任何网页上的内容，果冻网站不承担任何责任。
        </p>

        <p>
          7.
          您使用果冻网站所查询的数据如来源于第三方，果冻网站自身不控制、编辑或修改第三方网站上的信息的内容或其表现形式，仅对相关网站依法公示的信息如实展示。对于这些数据的真实性、完整性、及时性和准确性我们不作保证，因使用该第三方
          (包括其他用户)
          提供的内容或信息或参考该内容或信息而造成的损失由用户自负其责。另外，我们所提供的报告是根据现有已知的数据综合分析而得出相应的结论，仅为您的决策提供参考，对于您使用该报告而产生的任何后果，我们亦不承担责任。
        </p>

        <p>
          8.
          您确认并知悉，对于果冻提供的服务内容涉及根据法律法规或规范性文件需要数据查询或使用者获得数据相关方权利人授权的，
          您及您相关系统使用人员应当于数据查询与使用前自行获取相关权利人授权，
          尚未获得相关权利人授权而对相关信息进行查询与使用的，因此造成一切不利后果由您自行承担。
        </p>

        <p>
          9.
          是否使用本服务下载或取得任何资料应由您自行考量且自负风险，因任何资料之下载导致的您的电脑系统之任何损坏或数据流失等后果，由您自行承担。
        </p>

        <p>
          10.
          您自果冻网站或经由本服务取得的任何建议或信息，无论是书面或口头形式，将不构成本服务条款之外的任何保证。果冻网站所展示的信息、数据或内容并不对用户构成任何投资建议，用户应充分了解金融投资的风险，自主决策、理性投资并独立承担风险。
        </p>

        <p>
          11.
          果冻网站对由于您不正当使用本网站服务、或依据本网站信息进行交易引起的对任何第三方的损害不承担任何赔偿责任。您应对前述对果冻网站或第三方的损害进行完全的赔偿。
        </p>

        <p>
          12.
          本网站的内容及提供的服务不含任何明示性或暗示性的声明、保证或条件，包括但不限于关于真实性、适销性或适用于某一特定用途的明示或暗示性的声明、保证或条件，或者对其使用不会被中断或无误。
        </p>

        <p>
          13.
          果冻网站不声明或保证本网站或可从本网站下载的内容不带有计算机病毒或类似垃圾或破坏功能。
        </p>

        <p>
          14.
          果冻网站不担保本网站服务一定能满足用户的要求，不对页面内容的删除或储存失败负责。
        </p>

        <h2>九、法律管辖</h2>

        <p>1. 本协议的订立、执行和解释及争议的解决均应适用中国法律。</p>

        <p>
          2.
          若用户和果冻网站或果冻科技之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交果冻科技所在地有管辖权的人民法院管辖。
        </p>

        <h2>十、其他</h2>

        <p>
          1.
          果冻网站及果冻科技郑重提醒用户注意本协议中免除果冻网站及果冻科技的责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
        </p>

        <p>
          2.
          本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        </p>

        <p>
          3.
          本协议最终解释权归南京果冻知信信息技术有限公司所有，并且保留一切解释和修改的权力。
        </p>

        <p>本协议从2020年4月10日起适用。</p>
      </div>

      {/* <!-- 底部 --> */}
      <div class="footer">
        <a href="/legal-agreement/tos.html">服务协议</a>
        <span>-</span>
        <a href="/legal-agreement/privacy.html">隐私协议</a>
        <span>-</span>
        <a href="/legal-agreement/about.html">联系方式</a>
        <span class="code">浙ICP备20007987号-1</span>
      </div>
    </div>
  )
}
