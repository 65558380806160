import React, { useEffect } from 'react'
import about1 from '../images/about1.png'
import logo from '../images/logo.png'
import wx from '../images/qiyewx.png'
import '../styles/page.css'
export default function ContactInformation() {
  useEffect(() => {
    document.title = '联系方式'
  }, [])
  return (
    <div>
      {/* <!-- 头部 --> */}
      <div class="header">
        <img src={about1} alt="" class="bg" />
        <div class="header-con">
          {/* <!-- logo --> */}
          <img class="logo" src={logo} alt="吾道" />

          {/* <!-- 导航 --> */}
          <ul class="nav"></ul>
        </div>

        <h2>关于我们 / ABOUT US</h2>
      </div>

      {/* <!-- 菜单 --> */}
      <div class="menu">
        <div class="menu-con">
          <span>
            您的位置：
            <a href="/home" class="on">
              首页
            </a>
            <span>/</span>
            <a href="javascript:;">联系我们</a>
          </span>
        </div>
      </div>

      {/* <!-- 联系我们 --> */}
      <div class="law">
        <h1>果冻科技支持文档</h1>

        <p>
          债券发行人年度报告（以下简“年报”）披露工作是提高债券信息披露质量、强化信息披露风险的重要工具，也是发行人经营财务状况和偿债能力的集中反映，是投资者投资决策、债券信用风险管理的重要基础。
        </p>
        <p>
          果冻科技“刷年报”产品，根据《公开发行证券的公司信息披露内容与格式准则第38号—公司债券年度报告的内容与格式》、《公开发行证券的公司信息披露内容与格式准则第
          2号—年度报告的内容与格式》等监管部门对年报的披露要求，对传统的年报编写过程进行优化。将年报要求披露的基本信息、债券信息、财务数据等进行分类梳理，对各类数据的信息来源尽可能完整地收集，使得年报中大部分信息实现自动填充，尽可能减少用户编写年报中各处查找数据的繁琐过程。在编写后，可进行年报内部数据勾稽关系校验，并可通过交易所数据有效性校验的要求。
        </p>
      </div>

      {/* <!-- 联系方式 --> */}

      <div className="content">
        <img src={wx} alt="" className="wx_img" />
        <p>咨询电话：0571 - 8877 6816</p>
        <p>服务邮箱：service@iwudao.tech</p>
      </div>

      {/* <!-- 底部 --> */}
      <div class="footer">
        <a href="/legal-agreement/tos.html">服务协议</a>
        <span>-</span>
        <a href="/legal-agreement/privacy.html">隐私协议</a>
        <span>-</span>
        <a href="/legal-agreement/about.html">联系方式</a>
        <span class="code">浙ICP备20007987号-1</span>
      </div>
    </div>
  )
}
