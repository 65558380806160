import request from '../utils/request'

//获取二维码
function getQRCodeUrl(unique_key, code_type) {
  return request({
    url: 'sys/qrcode/',
    method: 'get',
    isLoading: false,
    params: {
      unique_key,
      code_type,
    },
  })
}

// 绑定微信
function bindWeChat(data) {
  return request({
    url: 'sys/user/wechat/',
    method: 'post',
    isLoading: false,
    data,
  })
}

//通过微信返回的code获取用户信息
function getTokenByCode(code) {
  return request({
    url: 'sys/wechat/info/',
    method: 'get',
    isLoading: false,
    params: {
      code,
    },
  })
}

//获取用户信息
function getUserInfo() {
  return request({
    url: 'sys/user/info/',
    method: 'get',
    isLoading: false,
  })
}

//获取用户token/没有用户则注册
function userLogon(params) {
  return request({
    url: 'sys/logon/',
    method: 'put',
    isLoading: false,
    data: params,
  })
}

//完善个人信息
function improveUserInfo(params) {
  return request({
    url: 'sys/user/mobile/',
    method: 'put',
    isLoading: false,
    data: params,
  })
}

//创建/绑定 企业
function bindEnterprise(company_name, token) {
  let headers = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return request({
    url: 'sys/comp/create/',
    method: 'post',
    isLoading: false,
    headers,
    data: {
      company_name,
    },
  })
}

//切换 企业
function switchEnterprise(unique_key, co_id, token) {
  let headers = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return request({
    url: 'sys/comp/switch/',
    method: 'put',
    isLoading: false,
    headers,
    data: {
      unique_key,
      co_id,
    },
  })
}

//切换 企业
function switchEnterpriseById(co_id) {
  return request({
    url: 'sys/comp/switch/',
    method: 'put',
    isLoading: false,
    data: {
      co_id,
    },
  })
}

//登录
function loginFunc(params) {
  return request({
    url: 'sys/login/',
    method: 'post',
    isLoading: false,
    data: params,
  })
}

//获取验证码
function getVerifyCode(params) {
  return request({
    url: 'sys/verify_code/',
    method: 'get',
    isLoading: false,
    params,
  })
}

//轮询获取token
function getTokenByKey(unique_key) {
  return request({
    url: 'sys/polling/',
    method: 'PUT',
    isLoading: false,
    data: {
      unique_key,
    },
  })
}

//产品订阅
function getSubsPlan() {
  return request({
    url: 'sys/subs_plan/',
    method: 'get',
    // isLoading: false,
  })
}

//获取产品订阅方案的数量
function getSubsPlanNum(prd_code) {
  return request({
    url: 'sys/license/item/',
    method: 'get',
    isLoading: false,
    params: {
      prd_code,
    },
  })
}

//产品推荐列表
function getProducts() {
  return request({
    url: 'sys/product/',
    method: 'get',
    isLoading: false,
  })
}

//获取ESN
function getEsnList() {
  return request({
    url: 'sys/esn/',
    method: 'get',
    // isLoading: false,
  })
}

//上传许可文件
function upLicense(data) {
  return request({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: 'sys/license/',
    method: 'post',
    // isLoading: false,
    data,
  })
}

//删除许可文件
function deleteLicense(license_id) {
  return request({
    url: 'sys/license/',
    method: 'DELETE',
    isLoading: false,
    params: {
      license_id,
    },
  })
}

//获取菜单
function getMenuOfPersonal() {
  return request({
    url: 'sys/menu/personal/',
    method: 'get',
    isLoading: false,
  })
}

/* ============================= 通知 ============================= */
//获取全部通知
function getNotices(page, page_size) {
  return request({
    url: `sys/msg/`,
    method: 'get',
    isLoading: false,
    returnData: true,
    params: {
      page,
      page_size,
    },
  })
}

//获取未读通知
function getUnreadNotices(page, page_size) {
  return request({
    url: `sys/msg/?read_flag=false`,
    method: 'get',
    isLoading: false,
    returnData: true,
    params: {
      page,
      page_size,
    },
  })
}

//消息修改已读状态接口
function setReadNotices() {
  return request({
    url: `sys/msg/read/`,
    method: 'put',
    isLoading: false,
    returnData: true,
    data: {
      spread: 'all',
    },
  })
}

//删除系统消息
function deleteNotices() {
  return request({
    url: `sys/msg/batch/`,
    method: 'delete',
    returnData: true,
    isLoading: false,
    data: {
      spread: 'read',
    },
  })
}

//获取更新日志
function getUpdateLogList(page, page_size) {
  return request({
    url: 'sys/log/updates/',
    method: 'get',
    isLoading: false,
    returnData: true,
    params: {
      page,
      page_size,
      sys_manage: true,
    },
  })
}

//获取企业列表
function getEnterprise(include_indiv_org) {
  return request({
    url: 'sys/comp/switch/',
    method: 'get',
    isLoading: false,
    returnData: true,
    params: {
      include_indiv_org,
    },
  })
}

//联系商务接口
function upContact(data) {
  return request({
    url: 'saas/contact/',
    method: 'post',
    isLoading: false,
    data,
  })
}

//获取推荐列表
function getRecommend() {
  return request({
    url: 'saas/recommend/',
    method: 'get',
    isLoading: false,
  })
}

//免费订阅
function setFreeSubscription(release_id) {
  return request({
    url: 'saas/recommend/',
    method: 'post',
    isLoading: false,
    data: {
      release_id,
    },
  })
}

//获取项目管理员
function getAdmin() {
  return request({
    url: 'sys/comp/user/administrator/',
    method: 'get',
    isLoading: false,
  })
}

//获取个人项目订阅方案
function getPersonalSubsPlan() {
  return request({
    url: 'sys/subs_plan/personal/',
    method: 'get',
    isLoading: false,
  })
}

//更改企业或组织名称
function editCompName(data) {
  return request({
    url: 'sys/comp/name/',
    method: 'PATCH',
    data,
  })
}

//添加成员
function addMember(data) {
  return request({
    url: '/sys/comp/user/import/',
    method: 'POST',
    data,
  })
}

//获取用户菜单
function getMenu() {
  return request({
    url: '/sys/user/menu/',
    })
}

//获取产品图片列表
function getPrdCover() {
  return request({
    url: '/saas/prd_cover/',
    method: 'get',
  })
}

//根据域名获取产品编码
function getPrdCode(link) {
  return request({
    url: '/saas/prd_code/',
    method: 'get',
    params:{
      link
    }
  })
}

export default {
  getQRCodeUrl,
  getTokenByCode,
  getUserInfo,
  loginFunc,
  getVerifyCode,
  getTokenByKey,
  improveUserInfo,
  bindEnterprise,
  switchEnterprise,
  userLogon,
  getSubsPlan,
  getProducts,
  getEsnList,
  upLicense,
  deleteLicense,
  getMenuOfPersonal,
  getNotices,
  getUnreadNotices,
  setReadNotices,
  deleteNotices,
  getUpdateLogList,
  getEnterprise,
  switchEnterpriseById,
  upContact,
  getRecommend,
  setFreeSubscription,
  getAdmin,
  getPersonalSubsPlan,
  getSubsPlanNum,
  editCompName,
  addMember,
  bindWeChat,
  getMenu,
  getPrdCover,
  getPrdCode
}
