import { GETCONFIGMANAGELIST } from '../constants'

const init = {
  configManageList: [],
  total: 0,
}
export default function getConfigManage(state = init, action) {
  if (action.type === GETCONFIGMANAGELIST) {
    return {
      ...state,
      configManageList: action.payload.data,
      total: action.payload.total,
    }
  }
  return state
}
