import {
  GETAPPLPERMISSIONLIST,
  GETAUTOAPPROVESTATUS,
  GETPERMISSIONFUCLIST,
  GETPERMISSIONFUNCTREELIST,
  GETPERMISSIONLIST,
  GETPERMISSIONLISTS,
  GETPERMISSIONMENULIST,
  GETPERMISSIONMENUTREELIST,
  GETPERMISSIONORGLIST,
  GETPERMISSIONORGLISTS,
  GETPERMISSIONUSERLIST,
  GETPERMISSIONUSERLISTS,
} from '../constants'

const init = {
  permissionList: [],
  permissionListAll: [],
  total: 0,
  permissionUserList: [],
  permissionUserLists: [],
  permissionUserTotal: 0,
  permissionOrgList: [],
  permissionOrgLists: [],
  permissionOrgTotal: 0,
  permissionMenuList: [],
  permissionFucList: [],
  permissionMenuTreeList: [],
  permissionFuncTreeList: [],
  applPermissionList: [],
  applPermissionTotal: 0,
  autoApproveStatus: false,
}
export default function getPermissionList(state = init, action) {
  if (action.type === GETPERMISSIONLIST) {
    return {
      ...state,
      permissionList: action.payload.arr,
      total: action.payload.total,
    }
  }
  if (action.type === GETPERMISSIONLISTS) {
    return {
      ...state,
      permissionListAll: action.payload,
    }
  }
  if (action.type === GETPERMISSIONUSERLIST) {
    return {
      ...state,
      permissionUserList: action.payload.data,
      permissionUserTotal: action.payload.total,
    }
  }
  if (action.type === GETPERMISSIONORGLIST) {
    return {
      ...state,
      permissionOrgList: action.payload.data,
      permissionOrgTotal: action.payload.total,
    }
  }
  if (action.type === GETPERMISSIONMENULIST) {
    return {
      ...state,
      permissionMenuList: action.payload,
    }
  }
  if (action.type === GETPERMISSIONFUCLIST) {
    return {
      ...state,
      permissionFucList: action.payload,
    }
  }
  if (action.type === GETPERMISSIONMENUTREELIST) {
    return {
      ...state,
      permissionMenuTreeList: action.payload,
    }
  }
  if (action.type === GETPERMISSIONFUNCTREELIST) {
    return {
      ...state,
      permissionFuncTreeList: action.payload,
    }
  }
  if (action.type === GETPERMISSIONUSERLISTS) {
    return {
      ...state,
      permissionUserLists: action.payload,
    }
  }
  if (action.type === GETPERMISSIONORGLISTS) {
    return {
      ...state,
      permissionOrgLists: action.payload,
    }
  }
  if (action.type === GETAPPLPERMISSIONLIST) {
    return {
      ...state,
      applPermissionList: action.payload,
      applPermissionTotal: action.payload.length,
    }
  }
  if (action.type === GETAUTOAPPROVESTATUS) {
    return {
      ...state,
      autoApproveStatus: action.payload,
    }
  }
  return state
}
