import "./index.scss";
import {
  Button,
  Drawer,
  Tabs,
  Divider,
  Badge,
  message,
  Spin,
  Empty,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactSVG } from "react-svg";
import { useGetState } from "ahooks";
import LoadingSvg from "./loading.svg";
import emptyImg from "./暂无icon@2x.png";

const { TabPane } = Tabs;

const NoticeDrawer = (props) => {
  const {
    getUnreadNoticesFunc,
    getNoticesFunc,
    setReadNoticesFunc,
    deleteNoticesFunc,
    setUnreadNoticesTotal,
    validationFunc,
    NoticesTitle,
  } = props;
  const [data, setData] = useState([]);

  const [choiceKey, setChoiceKey] = useState("未读");

  const [color, setColor] = useState("red");
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber, getPageNumber] = useGetState(1);
  const [total, setTotal] = useState(0);

  const tabs = ["未读", "全部"];

  const getUnreadNotices = (pageNumber, data, notLoading) => {
    if (!notLoading) {
      setLoading(true);
    }

    getUnreadNoticesFunc &&
      getUnreadNoticesFunc(pageNumber, 20).then((res) => {
        console.log("获取未读通知,第" + pageNumber + "页", res);
        if (validationFunc(res)) {
          setData(data.concat(res.data.data));
          setTotal(res.data.total);
          setUnreadNoticesTotal(res.data.total);
        } else {
          setData([]);
          setTotal(0);
        }
        setLoading(false);
      });
  };

  const getAllNotices = (pageNumber, data, notLoading) => {
    if (!notLoading) {
      setLoading(true);
    }
    getNoticesFunc &&
      getNoticesFunc(pageNumber, 20).then((res) => {
        console.log("获取全部通知,第" + pageNumber + "页", res);
        if (validationFunc(res)) {
          setData(data.concat(res.data.data));
          setTotal(res.data.total);
        } else {
          setData([]);
          setTotal(0);
        }
        setLoading(false);
      });
  };

  const tabChange = async (key) => {
    await setChoiceKey(key);
    await setPageNumber(1);
    if (key === "未读") {
      await getUnreadNotices(1, []);
    } else {
      await getAllNotices(1, []);
    }
  };

  useEffect(() => {
    if (props.visible) {
      getUnreadNotices(1, []);
    } else {
      setPageNumber(1);
      setTotal(0);
      setData([]);
      setChoiceKey("未读");
    }
  }, [props.visible]);

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    const pageNumber = getPageNumber() + 1;
    await setPageNumber(pageNumber);
    setTimeout(()=>{
      if (choiceKey === "未读") {
        getUnreadNotices(pageNumber, data, true);
      } else {
        getAllNotices(pageNumber, data, true);
      }
    },20)
    // setLoading(true);
  };

  //操作通知
  const handleNotices = (type) => {
    if (type === "未读") {
      setReadNoticesFunc &&
        setReadNoticesFunc().then(async (res) => {
          if (validationFunc(res)) {
            await setPageNumber(1);
            getUnreadNotices(1, []);
            message.success(res.message || res.msg);
          } else {
            message.error(res.message || res.msg);
          }
        });
    } else {
      deleteNoticesFunc &&
        deleteNoticesFunc().then(async (res) => {
          if (validationFunc(res)) {
            await setPageNumber(1);
            getAllNotices(1, []);
            message.success(res.message || res.msg);
          } else {
            message.error(res.message || res.msg);
          }
        });
    }
  };
  return (
    <Drawer
      className="noticeDrawer"
      width="600px"
      closable={false}
      title={NoticesTitle}
      placement="right"
      push={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              props.onClose();
            }}
            type="primary"
          >
            关闭
          </Button>
        </div>
      }
    >
      <Tabs
        className="myTabs noticeTabs"
        onChange={tabChange}
        activeKey={choiceKey}
        tabBarExtraContent={
          choiceKey == "未读" ? (
            <div
              className="typeSreach"
              onClick={() => {
                data.length > 0 && handleNotices("未读");
              }}
            >
              全部标记为已读
            </div>
          ) : (
            <div
              className="typeSreach"
              onClick={() => {
                data.length > 0 && handleNotices("全部");
              }}
            >
              清除所有已读消息
            </div>
          )
        }
      >
        {tabs.map((key) => (
          <TabPane tab={key} key={key}>
            <div className="infiniteScrollBox" id={"infiniteScrollBox" + key}>
              {loading ? (
                <Spin
                  className="mySpinLoading"
                  tip={props.tip}
                  indicator={
                    <ReactSVG
                      src={LoadingSvg}
                      className="anticon-spin anticon myLoadingIcon"
                    />
                  }
                ></Spin>
              ) : data.length > 0 ? (
                <InfiniteScroll
                  dataLength={data.length}
                  next={loadMoreData}
                  loader={<Divider className="loadingBox">Loading</Divider>}
                  hasMore={data.length < total}
                  scrollableTarget={"infiniteScrollBox" + key}
                  // endMessage={<Divider className="noDataDivider"></Divider>}
                >
                  <div className="cardBox">
                    {data.map((item, index) => (
                      <div
                        className="cardItem"
                        key={item.msg_id + Math.random()}
                      >
                        <Badge color={item.read_flag ? "#0068B2" : "#FE545F"} />
                        <div style={{ width: "100%" }}>
                          <div
                            className="top"
                            dangerouslySetInnerHTML={{
                              __html: item.msg_title,
                            }}
                          ></div>
                          <div className="bottom">
                            <div className="valueItem">
                              <div className="label">时间</div>
                              <div className="value">{item.created_time}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="emptyBox">
                  <Empty image={emptyImg} description='暂无数据'/>
                </div>
              )}
            </div>
          </TabPane>
        ))}
      </Tabs>
    </Drawer>
  );
};

export default NoticeDrawer;
