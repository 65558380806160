import { hasToken, setRedirectUrl, setUrlSearch } from "../../utils/cookie";
import { Redirect, Route, useLocation } from "react-router-dom";
const whiteList = ["/login", "/loginHtml", "/authredirect", "/404"];

const PrivateRoute = ({ component: Component, path, location,  ...rest }) => {

  if (hasToken() || whiteList.indexOf(location.pathname) >= 0) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      ></Route>
    );
  } else {
    setRedirectUrl(window.location.href);
    //记录哪个项目跳转过来的
    if (location.pathname === "/saas/guidePage") {
      setUrlSearch(window.location.href?.split("?")?.at(1));
    }
    return (
      <Redirect
        to={{
          pathname: "/loginHtml",
          search: "?redirect=" + window.location.href,
        }}
      />
    );
  }
};

export default PrivateRoute;
