import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from './reducers'
import { getToken } from '../utils/cookie'
const store = createStore(
  reducer,

  composeWithDevTools(applyMiddleware(thunk))
)

export default store
