import request from '../../utils/request'
import {
  ADDCOMPS,
  GETCOMPINFO,
  GETCOMPLIST,
  GETCOMPLISTALL,
  GETCOMPLISTFORNAMECODE,
  GETCOMPLISTS,
  GETCOMPMODE,
  GETCOMPSTATUS,
  GETDICITEM,
  GETINTEGRATIONLIST,
} from '../constants'

/**
 * 获取企业列表
 * @param {*} page
 * @param {*} page_size
 * @returns
 */
export const getCompList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/', {
      params,
    })
    dispatch({
      type: GETCOMPLIST,
      payload: res.data.data,
    })
  }
}
export const getCompLists = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/', {
      params,
    })
    // console.log(res.data.data)
    dispatch({
      type: GETCOMPLISTS,
      payload: res.data.data,
    })
  }
}
export const getCompListForNameCode = () => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/name-code/')
    dispatch({
      type: GETCOMPLISTFORNAMECODE,
      payload: res.data.data,
    })
  }
}

/**
 * 新增企业
 * @param {*} values
 * @returns
 */
export const addComps = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/comp/', values)
    dispatch({
      type: ADDCOMPS,
      payload: res.data.data,
    })
    return res
  }
}

/**
 * 删除企业
 * @param {*} id
 * @returns
 */
export const delComps = (id) => {
  return async (dispatch) => {
    const res = await request.delete(`sys/comp/${id}/`)
    return res
  }
}

/**
 * 获取企业类型
 * @param {*} params
 * @returns
 */

export const getDicItem = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/item/', {
      params,
    })
    // console.log(res)
    dispatch({
      type: GETDICITEM,
      payload: res.data.data.data,
    })
  }
}
export const getCompStatus = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/item/', {
      params,
    })
    dispatch({
      type: GETCOMPSTATUS,
      payload: res.data.data.data,
    })
  }
}

export const getCompMode = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/', {
      params,
    })
    dispatch({
      type: GETCOMPMODE,
      payload: res.data.data,
    })
  }
}

/**
 * 批量删除
 * @param {*} values
 * @returns
 */
export const batchDel = (values) => {
  return async (dispatch) => {
    await request.delete('sys/comp/', {
      params: {
        co_id__in: values,
      },
    })
  }
}

/**
 * 修改状态
 * @param {*} id
 * @param {*} code
 * @returns
 */
export const changeStaus = (id, code) => {
  return async (dispatch) => {
    await request.patch(`sys/comp/${id}/`, code)
  }
}

/**
 * 修改企业
 * @param {*} values
 * @returns
 */
export const editComps = (values) => {
  return async (dispatch) => {
    const res = await request.put(`sys/comp/${values.id}/`, values)
    return res
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/comp/check/', { params, isLoading })
    // console.log(res)
    return res
  }
}

export const getIntegrationList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/integration/', { params })
    dispatch({
      type: GETINTEGRATIONLIST,
      payload: res.data,
    })
  }
}
