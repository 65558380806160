export const closeWin = () => {
  if (document.addEventListener) {
    document.addEventListener(
      'WeixinJSBridgeReady',
      function () {
        // eslint-disable-next-line no-undef
        WelkinJSBridge.call('closeWindow')
        // eslint-disable-next-line no-undef, no-restricted-globals
        parent.WeixinJSBridge.call('closeWindow')
      },
      false
    )
  } else if (document.attachEvent) {
    document.attachEvent('onWeixinJSBridgeReady', function () {
      //这个可以关闭ios系统的手机
      // eslint-disable-next-line no-undef
      WeixinJSBridge.call('closeWindow')
    })
  }
  // 必须加最后这一句，否则关闭不了
  // eslint-disable-next-line no-undef
  WeixinJSBridge.call('closeWindow')
}
