import { GETUPDATELOGLIST, GETCURRENTUPDATELOGLIST } from '../constants'

const init = {
  total: 0,
  updatelogList: [],
  currentLogList: [],
}

export default function getUpdateLogList(state = init, action) {
  if (action.type === GETUPDATELOGLIST) {
    return {
      ...state,
      total: action.payload.total,
      updatelogList: action.payload.data,
    }
  }
  if (action.type === GETCURRENTUPDATELOGLIST) {
    return {
      ...state,
      currentLogList: action.payload.data,
    }
  }
  return state
}
