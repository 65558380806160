import request from '../../utils/request'
import {
  GETDICTIONARY,
  GETLOGINSTATUS,
  GETOPTLOG,
  GETOPTTYPE,
  GETOPTTYPELIST,
} from '../constants'
/**
 * 获取数据字典列表
 * @param {*} params
 * @returns
 */
export const getDictionaryList = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/', {
      params,
    })
    // console.log(res.data.data)
    dispatch({
      type: GETDICTIONARY,
      payload: res.data.data,
    })
  }
}

export const getDictionaryItem = (params) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/item/', { params })
    // console.log(res)
  }
}

export const addDictionary = (values) => {
  return async (dispatch) => {
    const res = await request.post('sys/dic/', values)
    // console.log(res)
  }
}

export const editDictionary = (values) => {
  return async (dispatch) => {
    const res = await request.put(`sys/dic/${values.id}/`, values)
    // console.log(res)
  }
}

export const delDictionary = (id) => {
  return async (dispatch) => {
    const res = await request.delete(`sys/dic/${id}/`)
    console.log(res)
  }
}

export const getOptType = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/item/', { params, isLoading })
    // console.log(res.data.data)
    dispatch({
      type: GETOPTTYPE,
      payload: res.data.data,
    })
  }
}

export const getOptTypeList = () => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/opt-type/')
    // console.log(res.data.data)
    dispatch({
      type: GETOPTTYPELIST,
      payload: res.data.data,
    })
  }
}

export const getLoginStatus = (params, isLoading) => {
  return async (dispatch) => {
    const res = await request.get('sys/dic/item/', { params, isLoading })
    // console.log(res.data.data)
    dispatch({
      type: GETLOGINSTATUS,
      payload: res.data.data,
    })
  }
}

export const getTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/dic/check/', {
      params,
      isLoading,
    })
  }
}

export const getDicItemTrigger = (params, isLoading) => {
  return async (dispatch) => {
    return await request.get('sys/dic/item/check/', {
      params,
      isLoading,
    })
  }
}
